import React, { Fragment, useRef, useState } from 'react';
import { Card, CardHeader, CardBody, ModalFooter, Button } from 'reactstrap';
import { H5, H3, LI, P, UL, Btn, Image } from '../../../../AbstractElements';
import { AboutM } from '../../../../Constant';
import { Book, CreditCard, Droplet, Home, Smartphone } from 'react-feather';
import userImage from '../../../../assets/images/user-profile/default-users.png';
import { FaIdCard, FaMoneyBill, FaPencilAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../../../Common/Popup/popup';
import SubCard from './SubCard';
import { useReactToPrint } from 'react-to-print';

const AboutMe = (props) => {
    const personalDetails = props.details;
    const [isShow, setIsShow] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [modal, setmodal] = useState(false);
    const componentRef = useRef(null);



    const handleEdit = () => {
        const editFormURL = `${process.env.PUBLIC_URL}/customers/customer-edit?id=${param1}`;
        navigate(editFormURL);
    };

    const AboutMeData = [
        {
            id: 1,
            icon: <i className="icofont icofont-id-card icofont-lg"></i>,
            designation: 'Subscriber Id',
            place: personalDetails.customer_details.subscriber_id
        },
        {
            id: 5,
            icon: <i className="icofont icofont-ui-v-card icofont-lg"></i>,
            designation: 'LCO No',
            place: personalDetails.customer_details.lco_no
        },
        {
            id: 2,
            icon: <i className="icofont icofont-phone-circle icofont-lg"></i>,
            designation: 'Contact No',
            place: `+${personalDetails.customer_details.country_code} ${personalDetails.customer_details.mobile}`
        },
        {
            id: 3,
            icon: <i className="icofont icofont-home icofont-lg"></i>,
            designation: 'Address',
            place: personalDetails.address
        },
        {
            id: 4,
            icon: <i className="icofont icofont-location-pin icofont-lg"></i>,
            designation: 'Billing Address',
            place: personalDetails.customer_details.billing_address
        },

        {
            id: 6,
            icon: <i className="icofont icofont-listing-number icofont-lg"></i>,
            designation: 'Tax In',
            place: personalDetails.customer_details.tax_in
        },

    ];
    const handleModal = () => {
        setmodal(!modal);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <Fragment>
            <Popup isOpen={modal} title="Subscription Card" toggler={handleModal} size="lg" >
                <Card >
                    <CardBody className='pb-0'>
                        <SubCard ref={componentRef} details={personalDetails}/>
                        <div className='mt-3 text-end'>
                            <button className='btn btn-primary light' onClick={handlePrint}>Print</button>
                        </div>
                    </CardBody>
                </Card>
            </Popup>

            <Card data-intro="This is your Your detail" className="step4">
                <CardHeader>
                    <H3 attrH3={{ className: 'p-0', style: { display: 'flex', alignItems: 'center' } }} >
                        {AboutM}
                        <FaPencilAlt title="Customer Edit " onClick={() => handleEdit()} style={{ cursor: 'pointer', marginLeft: 'auto', fontSize: '0.8em' }} />
                    </H3>
                </CardHeader>
                <div className={`collapse ${isShow ? 'show' : ''}`}>
                    <CardBody className="post-about">
                        <UL attrUL={{ className: 'simple-list' }}>
                            <div
                                // className="d-flex" 
                                className="d-flex cursor"
                                style={{ marginBottom: '30px' }}
                                onClick={handleModal}
                            >
                                <Image attrImage={{ className: 'img-60 img-fluid m-r-20 rounded-circle img-thumbnail', alt: 'user', src: userImage }} />
                                <div className="media-body flex-grow-1 ">
                                    <H5 className="d-block">{personalDetails.customer_name}</H5>
                                </div>
                            </div>
                            <div className="row"> {/* Add row class here */}
                                {AboutMeData.map((item) =>
                                    <div key={item.id} className="col-sm-6 col-md-4 col-xl-12" style={{ marginBottom: '10px', marginTop: '10px' }}> {/* Wrap each LI in a col-md-4 */}
                                        <LI>
                                            <div className="icon">{item.icon}</div>
                                            <div>
                                                <H5>{item.designation}</H5>
                                                <P attrPara={{ className: 'text-dark' }}>{item.place}</P>
                                            </div>
                                        </LI>
                                    </div>
                                )}
                            </div>
                        </UL>
                    </CardBody>
                </div>
            </Card>
        </Fragment>
    );
};

export default AboutMe;
