import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Btn } from '../../AbstractElements';
import { AddPayment, AddPayments, Cancel, LogOut, Print, ThermalPrint, key, key_secret, share } from '../../Constant';
import ItemDescription from './ItemDescription';
import { Col, Spinner } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from "../Common/Loader/Transparentspinner";
import { getGeneratePayment, getInvoice, getMakePayment, getPackageDet } from '../../CommonUrl';
import * as authServices from "../../Utils/authServices";
import { getMasterDataToken, postDataWithoutResData, postMasterToken } from '../../Utils/restUtils';
import { toast } from 'react-toastify';
import NavSign from '../../Billerq/Sign/NavSign';

const PrintComponent = () => {
    const location = useLocation();
    const searchParams = location.search;
    const [loading, setLoading] = useState(false)
    const params = new URLSearchParams(searchParams);
    const userId = params.get("order_id");
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [statusColor, setStatusColor] = useState('');
    const navigate = useNavigate();
    const [skipValue, setSkipValue] = useState('');
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getInvoiceData();
        getPackage();
    }, [])

    const getPackage = async () => {
        // setloading(true);
        const response = await getMasterDataToken(getPackageDet);
        if (response.status === true) {
            const packagetDetails = response.data;
            setSkipValue(packagetDetails);
        } else {
            HandleHomePageError(navigate, data.status)
        }
        // setloading(false);
    }

    const navigateDashboard = () => {
        const dashbaord = `${process.env.PUBLIC_URL}/dashboard/default`;
        navigate(dashbaord);
    };

    const getInvoiceData = async () => {
        setLoading(true)
        const data = await postDataWithoutResData(getInvoice + `/${userId}`);
        if (data.status === 200) {
            setInvoiceDetail(data.data.data);
            if (data.data.data.payment_status === "pending") {
                setStatusColor('#dc0808');
            } else if (data.data.data.payment_status === 'paid') {
                setStatusColor('#67c100');
            } else if (data.data.data.payment_status === 'paschal') {
                setStatusColor('#e56809');
            } else {
                setStatusColor('#534686');
            }
        }
        setLoading(false)
    }

    const handlePayment = async () => {
        setLoading(true);
        const result = await postMasterToken(getGeneratePayment, { order_id: userId });
        if (result.status === 200) {
            if (result.data.status === true) {
                const { id: orderId, email: email, name: name, mobile: mobile, amount: amount, currency: currency } = result.data.data;
                if (amount === "") {
                    alert("please enter amount");
                } else {
                    var options = {
                        key: key,
                        key_secret: key_secret,
                        amount: amount.toString(),
                        currency: currency,
                        name: "BillerQ",
                        description: "for payment purpose",
                        order_id: orderId,
                        handler: async function (response) {
                            const data = {
                                orderCreationId: orderId,
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                            };
                            setLoading(true);
                            let payment = await postMasterToken(getMakePayment, data);
                            if (payment.status === 200) {
                                if (payment.data.status === true) {
                                    toast.success(payment.data.message);
                                    getInvoiceData();
                                    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                                    setLoading(false);
                                } else {
                                    toast.error(payment.data.message);
                                    setLoading(false);
                                }
                            }
                        },
                        prefill: {
                            name: name,
                            email: email,
                            contact: mobile
                        },
                        notes: {
                            address: "Razorpay Corporate office"
                        },
                        theme: {
                            color: "#3399cc"
                        }
                    };
                    var pay = new window.Razorpay(options);
                    pay.open();
                }
            } else {
                toast.error(result.data.message);
                setLoading(false);
            }
        }
        setLoading(false);
    }

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <NavSign showProfile={true} buttonText={LogOut} />
            <div className='bg-light pt-5' style={{ height: '100%' }}>
                <ItemDescription
                    ref={componentRef}
                    idValue={invoiceDetail}
                    statusColor={statusColor}
                />
                <Col sm="12" className="text-center">
                    <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                        Print
                    </Btn>
                    {invoiceDetail.payment_status !== 'paid' && (
                        <Btn attrBtn={{ color: 'danger', className: 'me-2', onClick: () => handlePayment() }}>
                            {AddPayment}
                        </Btn>
                    )}
                    {skipValue.skip === true && (
                        <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: () => navigateDashboard() }}>
                            Skip
                        </Btn>
                    )}
                </Col>
            </div>
        </Fragment>
    );
};

export default PrintComponent;