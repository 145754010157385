import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, ListRole, Role, Search, Filter } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { deleteCustomer, viewAgent, viewRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const RoleList = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [agentLists, setAgentList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAgent();
    }, [searchKeyword]);

    const AgentColumns = [
        {
            name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
        },
        {
            name: <H4>Roles</H4>,
            selector: row => row.Roles,
            sortable: true,
            center: false,
        },
    ];

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/menu/role-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteCustomerList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { customer_id: row.id }
                    let customerDelete = await postClientUrlWithToken(authState.apiUrl, deleteCustomer, deleteRes);
                    if (customerDelete.status === 200) {
                        SweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        fetchAgent();
                    } else if (customerDelete.status >= 400 && customerDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (customerDelete.status >= 500 && customerDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                    setLoading(false);
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => handleEditClick(row.id)}>Edit</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteCustomerList()}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const fetchAgent = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, viewRole);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setAgentList(response.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Roles: e.name,
                    Status: e.status,
                    id: e.id
                }
            }))
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    const onSubmit = async (data) => {

    };

    return (
        <Fragment>

            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Role} parent="Users" title={Role} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{ListRole}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/menu/role-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>

                <CardBody>
                    <span className='custom-span'>

                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text"
                                        {...register('search')} />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={[
                                {
                                    name: <H4>Sl</H4>,
                                    selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                    width: '60px',
                                    sortable: false, // Disable sorting for SI
                                },
                                ...AgentColumns // All other columns remain the same
                            ]}
                            data={agentLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );

}

export default RoleList