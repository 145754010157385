import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, H4, Btn } from '../../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, StbModem, SubscripHeader, SubscriptionHeader, deactivationStb, reactivationStb, disconnectionStb, Search, Filter, CasProvider, Status, ConnectionType, applyPackage, Exportoexcel, ExportoPdf, Actions, Applypackage, reconnectionStb } from '../../../Constant';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { deleteStb, getProvider, getProviderRole, viewStb, viewStbRole, getstatscountStb, getstatscountStbRole, deleteStbRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import Popup from '../../Common/Popup/popup';
import DeactivateModal from './DeactivateModal';
import Disconnectmodal from './Disconnectmodal';
import ReactivateModal from './ReactiveModal';
import DatePicker from 'react-datepicker';
import ApplyPackModal from './ApplyPackModal'
import { conditionalRowStyles } from '../../Common/Rowstyle/Rowstyle';
import Widgets from '../../Common/Progress/Widgets';
import moment from 'moment';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import HandleApiError from '../../Common/ErrorImport/ErrorImport';
import { toast } from 'react-toastify';
import ReconnectModal from './ReconnectModal';

const StbListContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy");
    const sortedOrder = params.get("sortOrder");

    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [stbLists, setStbList] = useState([]);
    const [provider, setProvider] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        provider: '',
        connection: '',
        status: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [selectedRows, setselectedRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [modaldeactivate, setmodalDeactivate] = useState(false);
    const [modalreactivate, setmodalReactivate] = useState(false);
    const [modalDisconnect, setModalDisconnect] = useState(false);
    const [modalReconnect, setModalReconnect] = useState(false);
    const [modalPackage, setModalPackage] = useState(false);
    const [deactivateValue, setDeactivateValue] = useState({})
    const [reactivateValue, setReactivateValue] = useState({})
    const [disconnectValue, setdisconnectValue] = useState({})
    const [reconnectValue, setReconnectValue] = useState({})
    const [packageValue, setpackageValue] = useState({})
    const [staticwidgets, setstaticwidgets] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });

    const searchstb = useRef(null)
    const isCasProvider = useRef(null)
    const isConnection = useRef(null)
    const isStatus = useRef(null)

    const dectivateStbTitle = <span className='billerqColor bolder '>
        <h3>{deactivationStb}</h3>
    </span>

    const rectivateStbTitle = <span className='billerqColor bolder '>
        <h3>{reactivationStb}</h3>
    </span>

    const disconnectStbTitle = <span className='billerqColor bolder '>
        <h3>{disconnectionStb}</h3>
    </span>

    const reconnectStbTitle = <span className='billerqColor bolder '>
        <h3>{reconnectionStb}</h3>
    </span>

    const packageStbTitle = <span className='billerqColor bolder '>
        <h3>{applyPackage}</h3>
    </span>

    useEffect(() => {
        fetchStb(1);
        getwidgetsdata();
        providerData();
    }, [])


    const statusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "suspended", label: "Suspended" },
        { value: "disconnected", label: "Disconnected" }
    ];

    const connectionOptions = [
        { value: "cable", label: "Cable" },
        { value: "broadband", label: "Broadband" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: "other", label: "Others" },
    ];

    const StbColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            width: '70px',
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '50px',
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes("show-customer-profile")) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
            width: '100px',
        },
        {
            name: <H4>Name</H4>,
            id: 'customer_name',
            selector: row => row.Name,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            width: '100px',
            cell: row => (
                <div>
                    <span
                        className={`badge badge-${row.Status}`}
                    >
                        {row.Status}
                    </span>
                    {row.Status === 'disconnected' && (
                        <span>{row.StatusDate}</span>
                    )}
                </div>
            ),
        },
        {
            name: <H4>Connection Type</H4>,
            id: 'connection_type',
            selector: row => row.ConnectType,
            sortable: true,
            width: '110px',
        },
        {
            name: <H4>Serial No</H4>,
            id: 'serial_no',
            selector: row => row.Serial,
            sortable: true,
            wrap: true
        },
        {
            name: <H4>Stb Type</H4>,
            id: 'type',
            selector: row => row.StbType,
            sortable: true,
            width: '70px',
        },
        {
            name: <H4>STB No</H4>,
            id: 'stb_no',
            selector: row => row.StbNo,
            sortable: true,
            width: '100px',
        },
        {
            name: <H4>Install Date</H4>,
            id: 'activated_at',
            selector: row => row.Date,
            sortable: true,
            width: '130px',
            wrap: true
        },
        {
            name: <H4>Cas Provider</H4>,
            id: 'provider_name',
            selector: row => row.Cas,
            sortable: true,
        },
    ];

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/customers/stb-modem?sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/customers/stb-modem?sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const getwidgetsdata = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getstatscountStb : getstatscountStbRole);
        if (data.status === true) {
            setstaticwidgets(data.data);
        } else {
            HandleApiError(response.status)
        }
    }

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}${process.env.PUBLIC_URL}/customers/stb-modem-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteStbList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setspinnerTrans(true)
                if (result.value) {
                    const deleteRes = { stb_id: row.id }
                    const customerDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteStb : deleteStbRole, deleteRes);
                    if (customerDelete.status === 200) {
                        if(customerDelete.data.message==="success"){
                        SweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                    }else{
                        toast.error(customerDelete.data.message);
                    }
                        fetchStb(currentPage, perPage, filters.search, filters.start, filters.end, filters.provider, filters.connection, filters.status, sortConfig.column, sortConfig.order);
                    } else {
                        HandleApiError(response.status)
                    }
                }
                setspinnerTrans(false)
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {row.Status !== "suspended" && row.Status !== "disconnected" && (authState.userRole === 1 || authState.permissions.includes('stb-deactivate')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalDeactivate(row)}>Deactivate STB</DropdownItem>
                    )}
                    {row.Status !== "active" && row.Status !== "disconnected" && (authState.userRole === 1 || authState.permissions.includes('stb-activate')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalReactivate(row)}>Activate STB</DropdownItem>
                    )}
                    {row.Status !== "disconnected" && (authState.userRole === 1 || authState.permissions.includes('stb-disconnect')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalDisconnect(row)}>Disconnect STB</DropdownItem>
                    )}
                    {row.Status === "disconnected" && (authState.userRole === 1 || authState.permissions.includes('stb-reconnect')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalReconnect(row)}>Reconnect STB</DropdownItem>
                    )}
                    {row.Status === "active" && (authState.userRole === 1 || authState.permissions.includes('bulk-update-package')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleModalPackage(row)}>Apply Package</DropdownItem>
                    )}
                    {row.EditStatus === 1 && (authState.userRole === 1 || authState.permissions.includes('edit-stb')) && (
                        <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    )}
                    {row.DeleteStatus === 1 && (authState.userRole === 1 || authState.permissions.includes('remove-stb')) &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteStbList()}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handleModalDeactivate = (e) => {
        setDeactivateValue(e);
        setmodalDeactivate(!modaldeactivate);
    }
    const handleModalReactivate = (e) => {
        setReactivateValue(e);
        setmodalReactivate(!modalreactivate);
    }

    const handleModalDisconnect = (e) => {
        setdisconnectValue(e);
        setModalDisconnect(!modalDisconnect);
    }

    const handleModalReconnect = (e) => {
        setReconnectValue(e);
        setModalReconnect(!modalReconnect);
    }

    const handleSelectable = (selected) => {
        const selectedRow = selected.selectedRows.map((items) => items);
        setselectedRows(selectedRow);
    }

    const handleModalPackage = (e) => {
        setpackageValue(e);
        setModalPackage(!modalPackage);
    }
    const handleBundlePackage = () => {
        if (selectedRows.length > 0) {
            setpackageValue(selectedRows)
            setModalPackage(!modalPackage);
        } else {
            toast.error("Please select subscribers")
        }
    }


    const providerData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getProvider : getProviderRole);
        if (data.status === true) {
            setProvider(data.data);
        } else {
            HandleApiError(response.status)
        }
    };
    
    const fetchStb = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', casProviderId = '', connectiontype = '', statusValue = '', sortBy = '', sortOrder = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewStb +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&provider_id=${casProviderId}&connection_type=${connectiontype}&status=${statusValue}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            viewStbRole + `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&provider_id=${casProviderId}&connection_type=${connectiontype}&status=${statusValue}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        const responce_api = response.data.data;
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setStbList(responce_api.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Sub: <span>{e.subscriber_id}</span>,
                    Name: e.customer_name,
                    Serial: e.serial_no,
                    StbNo: e.stb_no,
                    Date: e.activated_at,
                    ConnectType: e.connection_type,
                    StbType: e.type,
                    StbMake: e.device_make,
                    Cas: e.provider_name,
                    Status: e.stb_status,
                    id: e.id,
                    customer_id: e.customer_id,
                    EditStatus: e.stb_edit,
                    DeleteStatus: e.stb_delete,
                    StatusDate: e.status_date
                }
            }))
            setTotalRows(response.data.total);
        } else {
            HandleApiError(response.status)
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchStb(page, perPage, filters.search, filters.start, filters.end, filters.provider, filters.connection, filters.status, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchStb(page, newPerPage, filters.search, filters.start, filters.end, filters.provider, filters.connection, filters.status, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const exportStbExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewStb + `?export=stb_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &provider_id=${filters?.provider ? filters.provider : ''}
            &connection_type=${filters?.connection ? filters.connection : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewStbRole + `?export=stb_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &provider_id=${filters?.provider ? filters.provider : ''}
            &connection_type=${filters?.connection ? filters.connection : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, activated_at, stb_no }) => ({ subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, activated_at, stb_no }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'STB Data');
            XLSX.writeFile(wb, 'stb_data.xlsx');
        } else {
            HandleApiError(response.status)
        }
        setspinnerTrans(false)
    };

    const exportStbPdf = async () => {
        setspinnerTrans(true)

        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewStb + `?export=stb_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &provider_id=${filters?.provider ? filters.provider : ''}
            &connection_type=${filters?.connection ? filters.connection : ''}&status=${filters?.status ? filters.status : ''}
            &sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewStbRole + `?export=stb_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &provider_id=${filters?.provider ? filters.provider : ''}
            &connection_type=${filters?.connection ? filters.connection : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, stb_no, activated_at }, index) =>
            ({ index: index + 1, subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, stb_no, activated_at })); if (response.status === true) {
                const doc = new jsPDF();
                const columns = ["#", "Subscriber Id", "Name", "Stb Status", "Connection Type", 'Cas Provider', "Serial No", "Stb Type", "Stb No", "Date"];
                const rows = dataToExport.map(({ index, subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, stb_no, activated_at }) =>
                    [index, subscriber_id, customer_name, stb_status, connection_type, provider_name, serial_no, type, stb_no, activated_at]
                );
                doc.autoTable({
                    head: [columns],
                    body: rows
                });
                doc.save("Stb-Data.pdf");
            } else {
            HandleApiError(response.status)
        }
        setspinnerTrans(false)
    };

    const handleFilter = async (e) => {
        e.preventDefault()
        setToggleClearRows(true);
        const search = searchstb.current.value;
        const provider = isCasProvider.current.value;
        const connection = isConnection.current.value;
        const status = isStatus.current.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            provider,
            connection,
            start,
            end,
            status
        });
        fetchStb(1, perPage, search, start, end, provider, connection, status);
    };

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={SubscriptionHeader} parent="Customers" title={StbModem} />
            <Widgets details={staticwidgets} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{SubscripHeader}</H3>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <CardBody className="dropdown-basic" style={{ borderRadius: '5px' }}>
                                {(authState.userRole === 1 || authState.permissions.includes('store-stb')) &&
                                    <Link to={`${process.env.PUBLIC_URL}/customers/stb-modem-add`} className="btn btn-primary me-2" style={{ marginLeft: '10px' }}>{Add}</Link>
                                }
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary button-stbspace">
                                        <i className="fa fa-gear me-1"></i>{Actions}
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportStbExcel()}><i className="fa fa-file-excel-o me-2"></i>{Exportoexcel}</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportStbPdf()}><i className="fa fa-file-pdf-o me-2"></i>{ExportoPdf}</DropdownItem>
                                        {(authState.userRole === 1 || authState.permissions.includes("bulk-update-package")) && (
                                            <DropdownItem style={toggleStyle} onClick={() => handleBundlePackage()}><i className="fa fa-plus-circle me-2"></i>{Applypackage}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        </div>

                    </div>
                </CardHeader>

                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleFilter}>
                        <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                            <Col sm="12" md='5' lg='2' className="form-element">
                                <input className="form-control" placeholder={Search} type="text"
                                    ref={searchstb} />
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <div style={{ position: 'relative', zIndex: 2 }}>
                                    <DatePicker
                                        className="form-control digits w-100"
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        monthsShown={2}
                                        popperPlacement="bottom"
                                        calendarClassName='parallel-calendar'
                                        placeholderText='Select Date Range'
                                    />
                                </div>

                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isCasProvider}>
                                    <option value="">{CasProvider}</option>
                                    {provider.map((values, index) => (
                                        <option key={index} value={values.id}>{values.name}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isConnection}>
                                    <option value="">{ConnectionType}</option>
                                    {connectionOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='2' className="form-element">
                                <select className="form-select" ref={isStatus} >
                                    <option value="">{Status}</option>
                                    {statusOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                            </Col>
                        </Row>
                    </Form>

                    <div className="order-history table-responsive">
                        <DataTable
                            columns={StbColumns}
                            data={stbLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRowStyles}
                            onChangePage={handlePageChange}
                            fixedHeader
                            selectableRows
                            onSelectedRowsChange={handleSelectable}
                            clearSelectedRows={toggledClearRows}

                        />
                    </div>
                </CardBody>
            </Card>
            <Popup isOpen={modaldeactivate} title={dectivateStbTitle} toggler={handleModalDeactivate}>
                <DeactivateModal
                    deactivateValue={deactivateValue}
                    stbLists={fetchStb}
                    handleModalDeactivate={handleModalDeactivate}
                    currentPageNo={currentPage}
                    perPagelist={perPage}
                />
            </Popup>
            <Popup isOpen={modalreactivate} title={rectivateStbTitle} toggler={handleModalReactivate}>
                <ReactivateModal
                    reactivateValue={reactivateValue}
                    stbLists={fetchStb}
                    handleModalReactivate={handleModalReactivate}
                    currentPageNo={currentPage}
                    perPagelist={perPage}
                />
            </Popup>

            <Popup isOpen={modalDisconnect} title={disconnectStbTitle} toggler={handleModalDisconnect}>
                <Disconnectmodal
                    disconnectValue={disconnectValue}
                    stbLists={fetchStb}
                    handleModalDisconnect={handleModalDisconnect}
                    currentPageNo={currentPage}
                    perPagelist={perPage}
                />
            </Popup>

            <Popup isOpen={modalReconnect} title={reconnectStbTitle} toggler={handleModalReconnect}>
                <ReconnectModal
                    reconnectValue={reconnectValue}
                    stbLists={fetchStb}
                    handleModalReconnect={handleModalReconnect}
                    currentPageNo={currentPage}
                    perPagelist={perPage}
                />
            </Popup>

            <Popup isOpen={modalPackage} title={packageStbTitle} toggler={handleModalPackage} size="xl">
                <ApplyPackModal
                    packageValue={packageValue}
                    onClose={handleModalPackage}
                    clearselectedRows={setToggleClearRows}
                />
            </Popup>


        </Fragment>
    );
};

export default StbListContainer;