import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Media, Label, Input, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, H4, Btn, Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../Utils/restUtils';
import { Add, Search, Filter, Vendor, VendorList } from '../../Constant';
import { useSelector } from 'react-redux';
import { deleteVendor, deleteVendorRole, statusVendor, statusVendorRole, getVendor, getVendorRole } from '../../CommonUrl';
import { toast } from 'react-toastify';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const VendorContainer = () => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [vendorList, setVendorList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (searchKeyword === '') {
            setLoading(true);
            fetchVendorData();
        }
    }, [searchKeyword]);

    const VendorColumns = [
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,
            width: '140px',
        },
        {
            name: <H4>Title</H4>,
            selector: row => row.title,
            sortable: true,
            width: '170px',
            wrap: true,
        },
        {
            name: <H4>Mobile</H4>,
            selector: row => row.mobile,
            sortable: true,
            width: '190px',
            wrap: true,
        },
        {
            name: <H4>Email</H4>,
            selector: row => row.email,
            sortable: true,
            width: '210px',
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: true,
        },
    ];

    const fetchVendorData = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getVendor : getVendorRole);
        if (response.status === true) {
            const filteredData = response.data.filter(item => item.name.toLowerCase().includes(searchKeyword.toLowerCase()));
            setVendorList(filteredData.map((e, index) => ({
                Si: index + 1,
                id: e.id,
                title: e.name,
                mobile: e.mobile_no ? "+" + e.mobile_no : "",
                email: e.email || "",
                Status: (
                    <Media body className="text-end icon-state">
                        <Label className="switch">
                            <Input
                                type="checkbox"
                                defaultChecked={e.status === "active"}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state"></span>
                        </Label>
                    </Media>
                ),
                action: <ActionCell row={{ id: e.id, vendor_id: e.id }} />
            })));
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        const reqSwitch = { vendor_id: itemId, status: checked ? 'inactive' : 'active' };

        const response = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusVendor : statusVendorRole, reqSwitch);
        if (response.data.status === true) {
            toast.success(response.data.message);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        fetchVendorData();
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            navigate(`${process.env.PUBLIC_URL}/Vendor/Vendor-edit?id=${row.id}`);
        };

        const deleteVendorList = async () => {
            const result = await SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            });

            if (result.value) {
                const deleteRes = { vendor_id: row.id };
                const response = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteVendor : deleteVendorRole, deleteRes);
                if (response.status === 200 && response.data.status === true) {
                    SweetAlert.fire('Deleted!', 'Your file has been deleted.', 'success');
                    fetchVendorData();
                } else {
                    handleError(response);
                }
            }
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('edit-add-on')) &&
                        <DropdownItem style={{ padding: '10px 20px', opacity: '0.8', fontSize: '14px' }} onClick={handleEditClick}>Edit</DropdownItem>
                    }
                    {(authState.userRole === 1 || authState.permissions.includes('remove-add-on')) &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={deleteVendorList}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handleError = (response) => {
        if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Vendor} parent="Expenses" title={Vendor} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{VendorList}</H3>
                    <Link to={`${process.env.PUBLIC_URL}/Vendor/Vendor-add`} className="btn btn-primary">{Add}</Link>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control custom-search-input" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange} />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={vendorList}
                            columns={[
                                {
                                    name: <H4>Sl</H4>,
                                    selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                    width: '60px',
                                    sortable: false, // Disable sorting for SI
                                },
                                ...VendorColumns // All other columns remain the same
                            ]}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default VendorContainer;

