import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Table } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  ChooseCompany,
  City, Country, EdituserAgent, Email, FirstName, Language, LastName, Locale, MobileNo, Role, Save, State, Status, UserAgent, zipcode
} from "../../../Constant";
import { CompanySubView, Updateagent, getCompanyAgent, getRole, viewAgent, } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, getMasterDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { SetLanguage } from "../../Common/Language/Language"
import AreaEdit from "../../Common/Area Field/AreaEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  firstname: yup.string().required().max(14, 'First Name must be at most 14 characters').label('First Name'),
  lastname: yup.string().required().max(14, 'Last Name must be at most 14 characters').label('Last Name'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  role: yup.mixed().required().label('Role'),
  language: yup.mixed().required().label('Language'),
  phoneNumber: yup.string().required('Phone number is required'),
})

const UserAgentEdit = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm(
    { resolver: yupResolver(schema) }
  );
  const { fields, append, remove } = useFieldArray({ control, name: 'rows' });
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("id");
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "suspended", label: "Suspended" },
    { value: "blocked", label: "Blocked" },
  ];

  useEffect(() => {
    companySubData();
    singleApiId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const companyApiData = await getClientUrlDataToken(authState.apiUrl, getCompanyAgent + `?user_id=${param1}`);
        if (companyApiData.status === true) {
          const data = companyApiData.data;

        const initialSelected = {};
        data.forEach((item, index) => {
          append({ companyName: '', area: '' });
          const companyOption = companyList.find(c => c.value === item.company_id);
          if (companyOption) {
            setValue(`rows[${index}].companyName`, companyOption);
            initialSelected[index] = companyOption.value;
          }
          const areaIds = JSON.parse(item.area_id);
          setValue(`rows[${index}].area`, areaIds);
        });
        setSelectedCompanies(initialSelected);
        } else {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
      setLoading(false);
    };

    if (companyList.length > 0) {
      fetchData();
    }
  }, [companyList, append, navigate, param1, setValue]);

  const handleAddRow = () => {
    append({ companyName: '', area: '' }); // Add default values as necessary
  };

  const handleCompanyChange = (index, selected) => {
    const selectedCompanyId = selected?.value || null;
    setSelectedCompanies((prevSelected) => {
      const newSelected = { ...prevSelected, [index]: selectedCompanyId };
      if (!selectedCompanyId) delete newSelected[index]; // Remove if deselected
      return newSelected;
    });
  }; 

  const companySubData = async () => {
    setLoading(true);
    const company_api = await getMasterDataToken(CompanySubView);
    if (company_api.status === true) {
      const companyList = company_api.data
        .filter((item) => item.subscription === 'yes')
        .map((item) => ({
          value: item.company_id,
          label: item.comapny_name,
        }));
      setCompanyList(companyList);
    } else {
      HandleApiError(company_api.status);
    }
    setLoading(false);
  };

  const singleApiId = async () => {
    setLoading(true);
    const api_id = await getClientUrlDataToken(authState.apiUrl, viewAgent + `?agent_id=${param1}`);
    const singleId = api_id.data.data[0];
    setValue("firstname", singleId.first_name);
    setValue("lastname", singleId.last_name);
    setValue("phoneNumber", singleId.country_code + singleId.mobile);
    setValue("countryCode", singleId.country_code);
    setValue("email", singleId.email);

    const data = await getClientUrlDataToken(authState.apiUrl, getRole);
    if (data.status === true) {
      const mappedrole = data.data.map((items) => ({
        value: items.id,
        label: items.name
      }
      ));
      setRoles(mappedrole);
      const equalRole = mappedrole.find((item) => item.value === singleId.role_id);
      setValue("role", { value: equalRole?.value, label: equalRole?.label });
      setValue("city", singleId.city);
      setValue("state", singleId.state);
      setValue("country", singleId.country);
      setValue("zipcode", singleId.zip_code);
      const language = SetLanguage.find(item => item.value == singleId.locale);
      setValue("language", language)
      const userStatus = statusOptions.find(item => item.value == singleId.status);
      setValue("status", userStatus)
    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const countryCode = data.countryCode;
    const phoneNumber = data.phoneNumber;
    if (phoneNumber.startsWith(countryCode)) {
      const b = phoneNumber.slice(countryCode.length);
      const companyData = data.rows.map(row => ({
        Company: row.companyName.value, // Extracting company ID
        Area: row.area, // List of area IDs for this company
      }));

      const postRequest = {
        agent_id: param1,
        first_name: data.firstname,
        last_name: data.lastname,
        mobile: b,
        email: data.email,
        role_id: data.role.value,
        country_code: data.countryCode,
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zipcode,
        locale: data.language.value,
        status: data.status.value,
        company_data: companyData,
      };
      const addCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl,
        Updateagent,
        postRequest
      );
      if (addCustomerResponse.status === 200) {
        if (addCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/menu/user`);
          toast.success(addCustomerResponse.data.message);
        } else {
          addCustomerResponse.data.message.forEach((err) => {
            toast.error(err);
          });
          setLoading(false);
        }
        setLoading(false);
      } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
    };
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}
      <Breadcrumbs mainTitle={UserAgent} parent="Customers" title={EdituserAgent} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{EdituserAgent}</H3>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {FirstName} <span className="requireStar">*</span>
                </Label>
                <input className="form-control" id="validationCustom01" type="text" {...register("firstname", { required: true })} />
                <p className="text-danger">{errors.firstname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{LastName} <span className="requireStar">*</span></Label>
                <input className="form-control" id="validationCustom02" type="text" {...register("lastname", { required: true })} />
                <p className="text-danger">{errors.lastname?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{MobileNo} <span className="requireStar">*</span></Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </span>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">{Email} <span className="requireStar">*</span></Label>
                <input className="form-control" id="validationCustom01" type="email" {...register("email", { required: true })} />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{Role} <span className="requireStar">*</span></Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={roles}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.role?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Language} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={SetLanguage}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.language?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom03">{City}</Label>
                <input className="form-control" id="validationCustom03" type="text" {...register("city")} />
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">{State}</Label>
                <input className="form-control" id="validationCustom01" type="text" {...register("state")} />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">{Country}</Label>
                <input className="form-control" id="validationCustom01" type="text"{...register("country")} />
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">{zipcode}</Label>
                <input className="form-control" id="validationCustom01" type="text" {...register("zipcode")} />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">{Locale}</Label>
                <input className="form-control" id="validationCustom01" type="text" {...register("local")} />
              </Col>
              <Col md="6">
                <Label className="form-label" >{Status} <span className="requireStar">*</span></Label>
                <Controller
                  name="status"
                  control={control} // Set the initial value to null
                  render={({ field }) => (
                    <Typeahead
                      options={statusOptions}
                      onChange={(value) => {
                        field.onChange(value); // Update the value in react-hook-form
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.type?.message}</p>
              </Col>
            </Row>
            <div style={{ paddingTop: '20px' }}>
              <H3>{ChooseCompany}</H3>
            </div>
            <div className="bg-subscription">
              <Row>
                <div className="table-responsive-set">
                  <Table className="table border subcription-table mt-4">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Area</th>
                        <th className="d-flex justify-content-between align-items-center">
                          Action
                          <button
                            type="button"
                            onClick={handleAddRow}
                            className="btn btn-primary btn-sm ml-auto"
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((item, index) => (
                        <tr key={item.id}>
                          <td className="col-sm-12 col-md-5">
                            <label>Name</label>
                            <Controller
                              name={`rows[${index}].companyName`}
                              control={control}
                              render={({ field }) => {
                                const filteredOptions = companyList.filter(
                                  (company) =>
                                    !Object.values(selectedCompanies).includes(company.value) ||
                                    selectedCompanies[index] === company.value
                                );
                                return (
                                  <Typeahead
                                    id={`company-select-${index}`}
                                    options={filteredOptions}
                                    onChange={(selected) => {
                                      field.onChange(selected);
                                      handleCompanyChange(index, selected);
                                    }}
                                    value={field.value}
                                    placeholder="Select company"
                                    isDisabled={false}
                                  />
                                );
                              }}
                            />
                          </td>
                          <td className=" col-sm-12 col-md-5">
                            <label>Area</label>
                            <AreaEdit
                              name={`rows[${index}].area`}
                              index={index} selectedCompany={selectedCompanies[index]}
                              control={control}
                              // errors={errors}
                              // ref={areaRef}
                            />
                          </td>
                          <td className="">
                            <button type="button"
                              onClick={() => {
                                remove(index);
                                handleCompanyChange(index, []);
                              }}
                              className="btn btn-danger">
                              <i className="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
            <FormGroup></FormGroup>
            <div className="text-start">
              <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default UserAgentEdit