import React, { Fragment, useEffect, useState } from 'react';
import { LI, P } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPackageDet, getShareApi, getSubDetail } from '../../../CommonUrl';
import { getMasterDataToken } from '../../../Utils/restUtils';
import { toast } from 'react-toastify';
import HandleHomePageError from '../../../Components/Common/ErrorImport/SignError';
import { setSubStatus } from '../../../Redux/Reducer';


const SubscriptionDetails = () => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState(authState.subscriDetails.packageExp); // Initial value
    const getTextColor = (value) => {
        if (value <= 5) {
            return '#DC0808'; // Red
        } else if (value <= 10) {
            return '#E56809'; // Orange
        } else {
            return '#534686'; // Blue
        }
    };

    const getBackgroundColor = (value) => {
        if (value <= 5) {
            return '#dc080833'; // Red
        } else if (value <= 10) {
            return '#e5680933'; // Orange
        } else {
            return '#53468633'; // Blue
        }
    };

    // Later in your component
    const textColor = getTextColor(value.package_expire_in);
    const backgroundColor = getBackgroundColor(value.package_expire_in);

    useEffect(() => {
        submitSubscription();
        subscriDetails();
    }, []);

    const submitSubscription = async () => {
        // setloading(true);
        const response = await getMasterDataToken(getPackageDet);
        if (response.status === true) {
            const packagetDetails = response.data;
            setValue(packagetDetails);
            if (response.data.planType === 'free' & response.data.status === 'Pending') {
                navigate('/subscription', { state: { id: authState.subStatus.company_id } });
            } else if (response.data.status === "Pending" & response.data.package_expire_in < -7) {
                let invoiceResponce = await getMasterDataToken(getShareApi + `?order_id=${response.data.invoice_id}`);
                if (invoiceResponce.status === true) {
                    const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${invoiceResponce.data}`;
                    navigate(toReciept);
                }
                else {
                    HandleHomePageError(navigate, data.status)
                }
            }
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        // setloading(false);
    }

    const subscriDetails = async () => {
        // setloading(true);
        const response = await getMasterDataToken(getSubDetail);
        if (response.status === true) {
            dispatch(setSubStatus(response.data));
            if (response.data.status === 'none') {
                navigate('/subscriptionAddition', { state: { id: response.data.company_id } });
            }
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        // setloading(false);
    }

    const navigateTo = async () => {
        if (value.planType === 'free') {
            navigate(`/subscription`, { state: { trial: true, fromDashboard: true, id: authState.subStatus.company_id } })
        } else if (value.package_expire_in <= -7) {
            localStorage.removeItem('profileURL');
            localStorage.removeItem('token');
            localStorage.removeItem('auth0_profile');
            localStorage.removeItem('Name');
            localStorage.setItem('authenticated', false);
            localStorage.setItem('login', false);
            dispatch(setLogout());
            navigate('/SignIn');
        } else if (value.invoice_id !== "") {
            let response = await getMasterDataToken(getShareApi + `?order_id=${value.invoice_id}`);
            if (response.status === true) {
                const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${response.data}`;
                navigate(toReciept);
            }
            else {
                HandleHomePageError(navigate, data.status)
            }
        } else {
            toast.success('No plan to Upgrade')
        }
    }

    return (
        <Fragment>
            <LI attrLI={{
                className: "p-md-2 cursor", style: { backgroundColor: backgroundColor },
                onClick: () => navigateTo()
            }} >
                <div className="sub-plan" style={{ marginRight: '10px' }}>
                    <div className=""><span className='fw-bolder'>{value.package_name}</span>
                        {value.planType === 'free' && (
                            value.package_expire_in > 0 ?
                                <P attrPara={{ className: "mb-0 font-roboto", style: { color: textColor } }} >
                                    Expires in <span style={{ fontWeight: 'bolder' }}>{value.package_expire_in}</span> Days
                                </P> :
                                <P attrPara={{ className: "mb-0 font-roboto", style: { color: textColor } }} >Upgrade your package</P>
                        )}
                        {value.planType === 'paid' && value.invoice_id !== "" && value.package_expire_in < 0 && (
                            <P attrPara={{ className: "mb-0 font-roboto", style: { color: textColor } }}>Pay Now</P>
                        )}
                    </div >
                </div >
            </LI >
        </Fragment >
    );
};

export default SubscriptionDetails;