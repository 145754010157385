import React, { useEffect, useState } from 'react';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { getCompanyArea } from '../../../CommonUrl';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';

const AreaEdit = ({ control, index, selectedCompany }) => {
    const [apiArea, setApiArea] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedCompany) {
            areaData(selectedCompany);
        }
    }, [selectedCompany]);

    const areaData = async (company) => {
        const data = await getClientUrlDataToken(authState.apiUrl, getCompanyArea + `?company_id=${company}`);
        if (data.status === true) {
            setApiArea(data.data);
        } else {
            const errorPage =
                data.status >= 400 && data.status <= 405 ? 'error-page1'
                    : data.status >= 500 && data.status <= 505 ? 'error-page4'
                        : 'error-page3';
            navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
        }
    };

    const handleSelectAll = (field) => {
        if (isAllSelected) {
            field.onChange([]); // Clear all selections
            setIsAllSelected(false);
        } else {
            const allOptions = apiArea.map(option => option.id);
            field.onChange(allOptions); // Select all options
            setIsAllSelected(true);
        }
    };

    return (
        <Controller
            name={`rows[${index}].area`}
            control={control}
            render={({ field }) => {
                const options = isAllSelected
                    ? apiArea // Show only area options when all are selected
                    : [{ id: 'select_all', name: 'Select All', isSelectAll: true }, ...apiArea]; // Include "Select All" otherwise

                return (
                    <div>
                        <Typeahead
                            id={`area-typeahead-${index}`}
                            clearButton
                            labelKey="name"
                            onChange={(value) => {
                                if (value.some(option => option.id === 'select_all')) {
                                    handleSelectAll(field);
                                } else {
                                    const selectedIds = value.map(option => option.id);
                                    field.onChange(selectedIds);
                                    setIsAllSelected(selectedIds.length === apiArea.length); // Toggle select all button based on selection
                                }
                            }}
                            selected={apiArea.filter(option => field.value?.includes(option.id))}
                            multiple
                            options={options}
                            placeholder="Choose area..."
                            renderMenuItemChildren={(option) => (
                                <div style={{
                                    color: option.isSelectAll ? '#534686' : 'black',
                                    fontWeight: option.isSelectAll ? '900' : 'normal',
                                    fontSize: option.isSelectAll ? '1.1em' : '1em'
                                }}>
                                    {option.name}
                                </div>
                            )}
                        />
                    </div>
                );
            }}
        />
    );
};

export default AreaEdit;
