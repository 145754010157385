import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import AboutMe from '../../../Bonus-Ui/Tour/Leftbar/AboutMe';
import FollowersProfile from '../../../Bonus-Ui/Tour/Leftbar/Followers';
import Followingsprofile from '../../../Bonus-Ui/Tour/Leftbar/Followings';
import FriendsProfile from '../../../Bonus-Ui/Tour/Leftbar/Friends';
import LatestPhotos from '../../../Bonus-Ui/Tour/Leftbar/LatestPhoto';
import PostFirst from '../../../Bonus-Ui/Tour/PostFirst';
import PostSecond from '../../../Bonus-Ui/Tour/PostSecond';
import PostThird from '../../../Bonus-Ui/Tour/PostThird';
import UserProfile from '../../../Bonus-Ui/Tour/UserProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCustomerDetails, getCustomerDetailsRole } from '../../../../CommonUrl';
import { getClientUrlDataToken } from '../../../../Utils/restUtils';
import StbTableTabs from '../../../Customers/Customer/UserProfile/SubAddonTab';
import SubAddonsTabs from '../../../Customers/Customer/UserProfile/SubAddonTab';
import StbTable from '../../../Customers/Customer/UserProfile/StbTable';
import PayInvoiceTabs from '../../../Customers/Customer/UserProfile/PayInvoiceTab';
import { toast } from 'react-toastify';
import Transparentspinner from '../../../Common/Loader/Transparentspinner';


const UsersProfileContain = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const authState = useSelector((state) => state.auth);
    const [personalDetails, setPersonalDetails] = useState({});
    const [loading, setloading] = useState(false)

    useEffect(() => {
        customerUserData();
    }, [])

    const customerUserData = async () => {
        setloading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerDetails +
            `?page=&page_length=&search=&customer_id=${param1}` : getCustomerDetailsRole + `?page=&page_length=&search=&customer_id=${param1}`);
        if (response.status === true) {
            setPersonalDetails(response.data);
        }
        else {
            toast.error(response.message);
            setloading(false);
        }
        setloading(false);
    }

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle="Customer Profile" parent="Customer" title="Customer Profile" />
            <Container fluid={true}>
                <div className="user-profile">
                    <Row>
                        {Object.keys(personalDetails).length > 0 && (
                            <UserProfile details={personalDetails} />
                        )}
                        <Col xl="3" md="12" className="xl-35 box-col-30">
                            <div className="default-according style-1 faq-accordion job-accordion">
                                <Row>
                                    <Col xl='12'>
                                        {Object.keys(personalDetails).length > 0 && (
                                            <AboutMe details={personalDetails} />
                                        )}
                                    </Col>
                                    {/* <Col xl="12" md="12" sm="6">
                                        <FollowersProfile />
                                    </Col>
                                    <Col xl="12" md="12" sm="6">
                                        <Followingsprofile />
                                    </Col> */}
                                    {/* <Col xl="12" md="12" sm="6">
                                        <LatestPhotos />
                                    </Col>
                                    <Col xl="12" md="12" sm="6">
                                        <FriendsProfile />
                                    </Col> */}
                                </Row>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="xl-65 box-col-70">
                            <Row>
                                <StbTable param={param1} />
                                <SubAddonsTabs param={param1} />
                            </Row>
                        </Col>
                        <PayInvoiceTabs param={param1} personalDetails = {personalDetails} />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default UsersProfileContain;