import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { AddSub, CustomerSubscription, Invoice, SubscriptionList, Search, Status, Filter, Customer, Export } from '../../../Constant';
import { viewSubscription, viewSubscriptionRole, getCustomers, statusSub, statusSubRole, getCustomersRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import moment from 'moment';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import Typeahead from '../../../CommonElements/TypeAhead';

const SubscriptionContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("expiry");
    const authState = useSelector((state) => state.auth);
    const [subscriptionLists, setSubscriptionList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        customerId: '',
        status: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [apiCustomer, setapiCustomer] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });
    const isSearched = useRef(null);
    const [isCustomer, setIsCustomer] = useState('');
    const isStatus = useRef(null);
    const statusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "suspented", label: "Suspented" },
        { value: "terminated", label: "Terminated" },
    ];

    useEffect(() => {
        fetchSubscription(1);
        customerData();
    }, []);

    useEffect(() => {
        const customerOptions = apiCustomer.map((customer) => ({
            value: customer.id,
            label: customer.name,
        }));
        setCustomerOptions(customerOptions);
    }, [apiCustomer]);

    const subscriptionColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            width: '70px',
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '55px',
        },
        {
            name: <H4>Payment Status</H4>,
            selector: row => row.PaymentStatus,
            cell: row => (
                <span
                    className={`badge badge-${row.PaymentStatus}`}
                >
                    {row.PaymentStatus}
                </span>
            ),
            width: '95px',
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            wrap: true,
            width: '145px',
        },
        {
            name: <H4>Customer</H4>,
            id: 'name',
            selector: row => row.Customer,
            sortable: true,
            wrap: true,
            width: '125px',
        },
        {
            name: <H4>Invoice Status</H4>,
            selector: row =>
                <span
                    className={`badge badge-${row.Invoice_status}`}
                >
                    {row.Invoice_status}
                </span>,
            wrap: true,
            width: '125px',
        },
        {
            name: <H4>Subscription Plans</H4>,
            id: 'package_name',
            selector: row => row.SubscriptionPlans,
            sortable: true,
            wrap: true,
            width: '155px',
        },
        {
            name: <H4>STB No</H4>,
            id: 'stb_no',
            selector: row => row.StbNo,
            sortable: true,
            wrap: true,
            width: '135px',
        },
        {
            name: <H4>Type</H4>,
            id: 'type',
            selector: row => row.type,
            sortable: true,
            wrap: true,
            width: '125px',
        },
        {
            name: <H4>Start Date</H4>,
            id: 'start_date',
            selector: row => row.PlanStartDate,
            sortable: true,
            wrap: true,
            width: '170px'
        },
        {
            name: <H4>End Date</H4>,
            id: 'end_date',
            selector: row => row.PlanEndDate,
            sortable: true,
            wrap: true,
            width: '170px'
        },
        {
            name: <H4> Subscription Status</H4>,
            selector: row =>
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status}
                </span>,
            wrap: true,
            width: '125px',
        },
    ];

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchSubscription(currentPage, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, column.id, newOrder);
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePaymentClick = (id) => {
        const payFormURL = `${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${id}`;
        navigate(payFormURL);
    }

    const handleChange = (selectedOption) => {
        setIsCustomer(selectedOption);
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const customerData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        } else {
            handleApiError(data.status);
        }
    };
    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleSwitchToggle = async (event, subId) => {
            event.preventDefault();
            SweetAlert.fire({
                title: 'Confirm deletion?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then(async (result) => {
                if (!result.value) {
                    return;
                }
                const reqSwitch = {
                    subscription_id: subId,
                    status: 'off'
                };
                const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusSub : statusSubRole, reqSwitch);
                setspinnerTrans(true)
                if (statusChange.status === 200) {
                    if (statusChange.data.status === true) {
                        SweetAlert.fire('Success', statusChange.data.message, 'success');
                        fetchSubscription(page, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
                        setspinnerTrans(false)
                    } else {
                        SweetAlert.fire('Error', statusChange.data.message, 'error');
                        setspinnerTrans(false)
                    }
                } else {
                    handleApiError(statusChange.status);
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => handlePaymentClick(row.order_id)}>{Invoice}</DropdownItem>
                    {row.recurring === 'yes' &&
                        <DropdownItem style={toggleStyle} onClick={(event) => handleSwitchToggle(event, row.id)}>
                            Turn off Recurring
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const fetchSubscription = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', customer_id = '', statusValue = '', sortBy = '', sortOrder = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSubscription +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}
            &customer_id=${customer_id}&status=${statusValue}&expiry=${param1}&sort_by=${sortBy}&sort_order=${sortOrder}` :
            viewSubscriptionRole + `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}
            &end_date=${end_date}&customer_id=${customer_id}&status=${statusValue}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setSubscriptionList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    PaymentStatus: e.payment_status,
                    SubId: e.subscriber_id,
                    Customer: e.customer_name,
                    StbNo: e.stb_no,
                    SubscriptionPlans: e.package_name,
                    type: e.package_type,
                    PlanStartDate: e.plan_date,
                    PlanEndDate: e.recurring_date,
                    Status: e.status,
                    Invoice_status: e.order_status,
                    order_id: e.order_no,
                    customer_id: e.customer_id,
                    recurring: e.recurring,
                    id: e.id
                }
            }))
            setTotalRows(response.data.total);

        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    }

    const exportSubscriptionExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewSubscription + `?export=subscription_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''} 
            &customer_data=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewSubscriptionRole + `?export=subscription_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''} 
            &customer_data=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`)

        const dataToExport = response.data.map(({ payment_status, subscriber_id, customer_name, package_name, stb_no, package_type, start_date }) => ({ Payment_Status: payment_status, Subscription_Id: subscriber_id, Customer: customer_name, Subscription_Plans: package_name, STB_No: stb_no, Type: package_type, Plan_Start_Date: start_date }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Subscription Data');
            XLSX.writeFile(wb, 'subscription_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportSubscriptionPdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewSubscription + `?export=subscription_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''} 
            &customer_data=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewSubscriptionRole + `?export=subscription_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''} 
            &customer_data=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`)

        const dataToExport = response.data.map(({ payment_status, subscriber_id, customer_name, package_name, stb_no, package_type, start_date }, index) => ({ Sl: index + 1, payment_status, subscriber_id, customer_name, package_name, stb_no, package_type, start_date }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Payment Status", "Subscription Id", "Customer", "Subscription Plans", "STB No", "Type", "Plan Start Date",];
            const rows = dataToExport.map(({ payment_status, subscriber_id, customer_name, package_name, stb_no, package_type, start_date }, index) => [index + 1, payment_status, subscriber_id, customer_name, package_name, stb_no, package_type, start_date]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("subscription_data.pdf");
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchSubscription(page, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchSubscription(page, newPerPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const handleFilter = async (e) => {
        e.preventDefault()
        const search = isSearched.current.value;
        const customerId = isCustomer?.value;
        const status = isStatus.current.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            customerId,
            start,
            end,
            status
        });
        fetchSubscription(1, perPage, search, start, end, customerId, status);
    };

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={CustomerSubscription} parent="Billing" title={CustomerSubscription} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{SubscriptionList}</H3>
                    <div>
                        <CardBody className="dropdown-basic" style={{ borderRadius: '5px' }}>
                            {(authState.userRole === 1 || authState.permissions.includes('store-subscription')) &&
                                <Link to={`${process.env.PUBLIC_URL}/billing/subscription-add`} className="btn btn-primary me-md-2" >{AddSub}</Link>
                            }
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportSubscriptionExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportSubscriptionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>

                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input className="form-control" placeholder={Search} id="search" type="text"
                                        ref={isSearched} />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element" >
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            id='date_range'
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'

                                        />
                                    </div>

                                </Col>
                                <Col sm="12" md="5" lg="2" className="form-element">
                                    <Typeahead
                                        options={customerOptions}
                                        onChange={handleChange}
                                        placeholder="Customer..."
                                    />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" id="status" ref={isStatus}>
                                        <option value="">{Status}</option>
                                        {statusOptions.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>


                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={subscriptionColumns}
                            data={subscriptionLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default SubscriptionContainer;