import React from 'react';
import { H4 } from '../../AbstractElements';

export const AreaColumns = [
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        width:'400px',
    },
    {
        name: <H4>Area Code</H4>,
        selector: row => row.AreaCode,
        sortable: true,
        width:'250px',
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        width:'200px',
        center: true,
    },
];

export const ProviderColumns = [
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        // center: true,
        width:'210px',
        wrap:true,
    },
    {
        name: <H4>Description</H4>,
        selector: row => row.Description,
        sortable: true,
        // center: true,
        width:'160px',
        wrap:true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        center: true,
        width:'280px',
    },
];

export const HeaderColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width:'180px',
    },
    {
        name: <H4>Title</H4>,
        selector: row => row.Title,
        sortable: true,
        // center: true,
        width:'210px',
        wrap:true,
        
    },
    {
        name: <H4>Description</H4>,
        selector: row => row.Description,
        sortable: true,
        // center: true,
        width:'160px',
        wrap:true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        center: true,
        width:'280px',
    },
];
export const VendorColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width:'130px',
    },
    {
        name: <H4>Title</H4>,
        selector: row => row.title,
        sortable: true,
        // center: true,
        width:'170px',
        wrap:true,
        
    },
    {
        name: <H4>Mobile</H4>,
        selector: row => row.mobile,
        sortable: true,
        // center: true,
        width:'190px',
        wrap:true,
        
    },
    {
        name: <H4>Email</H4>,
        selector: row => row.email,
        sortable: true,
        // center: true,
        width:'210px',
        wrap:true,
        
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        center: true,
        width:'280px',
    },
];

export const PaymentColumns = [
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        width:'250px',   
    },
    {
        name: <H4>Code</H4>,
        selector: row => row.Code,
        sortable: true,
        width:'190px',
    },
    {
        name: <H4>Order</H4>,
        selector: row => row.Order,
        sortable: true,
        width:'150px',
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        center: true,
    },
];

export const CategoryColumns = [
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        width:'280px',
    },
    {
        name: <H4>Type</H4>,
        selector: row => row.Type,
        sortable: true,
        width:'200px',
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        width:'100px',
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        center: true,
    },
];

export const TaxGroupColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Sl,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Title</H4>,
        selector: row => row.Name,
        sortable: true,
        // center: true,
    },
    {
        name: <H4>Tax</H4>,
        selector: row => row.Tax,
        sortable: true,
        // center: true,
    },
    {
        name: <H4>Description</H4>,
        selector: row => row.Description,
        sortable: true,
        // center: true,
    },
    {
        name: <H4>Total %</H4>,
        selector: row => row.Total,
        sortable: true,
        // center: true,
    },
   
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        cell: row => <ActionCell row={row} />,
        sortable: true,
        center: true,
    },
];

