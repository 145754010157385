import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { Controller, useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Activate, Add, AddSub, Invoice, Payment, ActiveSubscription, ActiveSubList, Search, Status, Filter, Export, Customer } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { activatesub, activatesublist, getCustomers, getCustomersRole, viewSubscriptionRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import Typeahead from '../../../CommonElements/TypeAhead';

const ActivateSub = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [subscriptionLists, setSubscriptionList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        customerId: '',
        status: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const isSearched = useRef(null);
    const [isCustomer, setIsCustomer] = useState('');
    const isStatus = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });

    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const statusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "pending", label: "Pending" },
        { value: "terminated", label: "Terminated" },
    ]

    useEffect(() => {
        fetchSubscription(1);
        customerData();
    }, []);

    useEffect(() => {
        const customerOptions = apiCustomer.map((customer) => ({
            value: customer.id,
            label: customer.name,
        }));
        setCustomerOptions(customerOptions);
    }, [apiCustomer]);

    const subscriptionColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            width: '70px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            center: true,
            width: '55px'
        },
        {
            name: <H4>Payment Status</H4>,
            selector: row => row.PaymentStatus,
            cell: row => (
                <span
                    className={`badge badge-${row.PaymentStatus}`}
                >
                    {row.PaymentStatus.charAt(0).toUpperCase() + row.PaymentStatus.slice(1).replace('_', ' ')}
                </span>
            ),
            width: '90px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            wrap: true,
            width: '120px'
        },
        {
            name: <H4>Customer</H4>,
            id: 'name',
            selector: row => row.Customer,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status.replace('_', ' ')}
                </span>
            ),
        },
        {
            name: <H4>STB No</H4>,
            id: 'stb_no',
            selector: row => row.StbNo,
            sortable: true,
        },
        {
            name: <H4>Subscription Plans</H4>,
            id: 'package_name',
            selector: row => row.SubscriptionPlans,
            sortable: true,
            wrap: true,
            width: '120px'
        },
        {
            name: <H4>Invoice No</H4>,
            id: 'invoice_no',
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customerid}`}>
                    {row.InvoiceNo}
                </Link>,
            sortable: true,
            width: '135px'
        },
        {
            name: <H4>Type</H4>,
            id: 'type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: <H4>Plans Start Date</H4>,
            id: 'start_date',
            selector: row => row.PlanStartDate,
            sortable: true,
            wrap: true,
        },
    ];

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchSubscription(currentPage, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, column.id, newOrder);
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleChange = (selectedOption) => {
        setIsCustomer(selectedOption);
    };

    const customerData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        } else {
            handleApiError(data.status);
        }
    };


    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleActivate = async () => {
            SweetAlert.fire({
                title: 'Are you sure you want to Activate?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Activate',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            }).then(async (result) => {
                setspinnerTrans(true);
                if (result.value) {
                    const postRequest = {
                        subscription_id: row.id,
                        status: 'active'
                    };
                    const addCustomerResponse = await postClientUrlWithToken(
                        authState.apiUrl,
                        activatesub,
                        postRequest
                    );
                    if (addCustomerResponse.status === 200) {
                        if (addCustomerResponse.data.status === true) {
                            toast.success(addCustomerResponse.data.message);
                            fetchSubscription(currentPage, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
                        } else {
                            addCustomerResponse.data.message.map((e) => {
                                toast.error(e);
                                setspinnerTrans(false)
                            });
                        }
                    } else {
                        handleApiError(addCustomerResponse.status);
                    }
                }
                setspinnerTrans(false)
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('activate-subscription')) &&
                        <DropdownItem style={toggleStyle} onClick={() => handleActivate()}>{Activate}</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };


    const fetchSubscription = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', customer_id = '', statusValue = '', sortBy = '', sortOrder = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? activatesublist +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customer_id}
            &status=${statusValue}&sort_by=${sortBy}&sort_order=${sortOrder}` : viewSubscriptionRole +
        `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customer_id}
            &status=${statusValue}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setSubscriptionList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    PaymentStatus: e.payment_status,
                    SubId: e.subscriber_id,
                    Customer: e.customer_name,
                    StbNo: e.stb_no,
                    SubscriptionPlans: e.package_name,
                    type: e.package_type,
                    PlanStartDate: e.plan_date,
                    Status: e.order_status,
                    InvoiceNo: e.invoice_number,
                    order_id: e.order_no,
                    customer_id: e.customer_id,
                    id: e.id,
                }
            }))
            setTotalRows(response.data.total);

        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    }
    const exportActiveSubExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            activatesublist + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewSubscriptionRole + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date }) => ({ payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Active sub Data');
            XLSX.writeFile(wb, 'Active_sub_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportActiveSubPdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            activatesublist + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewSubscriptionRole + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.status ? filters.status : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date }, index) => ({ Sl: index + 1, payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Payment Status", "Subscription Id", "Customer", "Status", "STB No", "Subscription Plans", "Invoice No", "Type", "Plan Start Date"];
            const rows = dataToExport.map(({ payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date }, index) => [index + 1, payment_status, subscriber_id, customer_name, status, stb_no, package_name, invoice_number, package_type, start_date]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("active_sub_data.pdf");
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchSubscription(page, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchSubscription(page, newPerPage, filters.search, filters.start, filters.end, filters.customerId, filters.status, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };
    const handleFilter = async (e) => {
        e.preventDefault()
        const search = isSearched.current.value;
        const customerId = isCustomer?.value;
        const status = isStatus.current.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            customerId,
            start,
            end,
            status
        });
        fetchSubscription(1, perPage, search, start, end, customerId, status);

    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={ActiveSubscription} parent="Billing" title={ActiveSubscription} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -35px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{ActiveSubList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes('store-subscription')) &&
                                <Link to={`${process.env.PUBLIC_URL}/billing/subscription-add`} className="btn btn-primary me-2" >{AddSub}</Link>
                            }
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportActiveSubExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportActiveSubPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>
                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input className="form-control" placeholder={Search} type="text"
                                        ref={isSearched} />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element" >
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'
                                        />
                                    </div>
                                </Col>
                                <Col sm="12" md="5" lg="2" className="form-element">
                                    <Typeahead
                                        options={customerOptions}
                                        onChange={handleChange}
                                        placeholder="Customer..."
                                    />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isStatus}>
                                        <option value="">{Status}</option>
                                        {statusOptions.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={subscriptionColumns}
                            data={subscriptionLists}
                            progressPending={loading}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ActivateSub;