import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Btn, H3, H4 } from '../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { deleteCustomerDueImportedLog, deleteCustomerImportedLog, getCustomerDueImportedLog, getCustomerImportedLog } from '../../../CommonUrl';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Export, Search } from '../../../Constant';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ImportDueList = () => {
  const authState = useSelector((state) => state.auth);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [customerList, setcustomerList] = useState([]);
  const [spinnerTrans, setspinnerTrans] = useState(false);
  const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const CustomerColumns = [
    {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '50px',
    },
    {
      name: <H4>Comment</H4>,
      selector: row => row.Comment,
      sortable: true,
      wrap: true,
    },
    {
      name: <H4>Subscriber Id</H4>,
      selector: row => row.Sub,
      sortable: true,
      wrap: true,
    },
    {
      name: <H4>Amount</H4>,
      selector: row => row.Amount,
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    importCustomerlog(1);
  }, [searchKeyword])

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const importCustomerlog = async (page, size = perPage) => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getCustomerDueImportedLog + `?page=${page}&page_length=${size}`);
    if (response.status == true) {
      const SerialNo = (page - 1) * size + 1;
      setcustomerList(response.data.data.map((e, index) => {
        return {
          Si: SerialNo + index,
          Sub: <span>{e.subscriber_id}</span>,
          Amount: e.amount,
          Comment: e.comments?.replace(/^\["|"]$/g, ''),
        }
      }))
      setTotalRows(response.data.total);
    } else {
      setspinnerTrans(false)
      toast.success("Something went wrong while fetching data");
    }
    setspinnerTrans(false)
  }

  const handlePageChange = page => {
    importCustomerlog(page, perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    importCustomerlog(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleRemoveImpoted = async () => {
    setspinnerTrans(true)
    const deleteLogList = await postClientUrlWithToken(authState.apiUrl, deleteCustomerDueImportedLog);
    if (deleteLogList.status === 200) {
      if (deleteLogList.data.status == true) {
        toast.success(deleteLogList.data.message);
        importCustomerlog();
      } else {
        toast.error(deleteLogList.data.message);
        setspinnerTrans(false)
      }
    } else if (deleteLogList.status >= 400 && deleteLogList.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (deleteLogList.status >= 500 && deleteLogList.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setspinnerTrans(false)
  }

  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const exportCustomerExcel = async () => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getCustomerDueImportedLog + `?export=customerDueLog_data`)
    const dataToExport = response.data.map(({ subscriber_id, amount, comments }) => ({ Subscriber_Id: subscriber_id, Comments: comments.replace(/^\["|"]$/g, ''), Amount: amount }));
    if (response.status === true) {
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Customer Data');
      XLSX.writeFile(wb, 'customer_due_log_data.xlsx');
    } else {
      handleApiError(response.status);
    }
    setspinnerTrans(false)
  };

  const exportCustomerPdf = async () => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getCustomerDueImportedLog + `?export=customerDueLog_data`);
    const dataToExport = response.data.map(({ subscriber_id, amount, comments }, index) => ({ Sl: index + 1, subscriber_id, amount, comments }));
    if (response.status === true) {
      const doc = new jsPDF();
      const heading = "Customer Log list";
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text(heading, 14, 22);

      const columns = ["Sl", "Subscriber Id", "Amount", "Comments"];
      const rows = dataToExport.map(({ subscriber_id, amount, comments }, index) => [index + 1, subscriber_id, amount, comments]);
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 27
      });
      doc.save("customer_due_log_list.pdf");
    } else {
      handleApiError(response.status);
    }
    setspinnerTrans(false)
  };

  return (
    <>
      {spinnerTrans && <Transparentspinner />}
      <Breadcrumbs mainTitle="Customer Due Imported Log" parent="customers / customer" title="customer due imported log" />
      <Card className='cardWidth'>
        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H3>Customer Due Imported Log</H3>
          <div style={{ display: 'flex' }}>
            <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginRight: '10px' }}>
              <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                {Export} <i className="icofont icofont-arrow-up"></i>
              </DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem style={toggleStyle} onClick={() => exportCustomerExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                <DropdownItem style={toggleStyle} onClick={() => exportCustomerPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Btn attrBtn={{ color: 'danger', onClick: () => handleRemoveImpoted() }} ><i className="fa fa-trash-o "></i> Clear Log </Btn>
          </div>
        </CardHeader>
        <CardBody>
          <span className='custom-span'>
            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
              <Col xs="6" sm="4" md="2" lg="2">
                <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </span>
          <div className="order-history table-responsive">
            <DataTable
              columns={CustomerColumns}
              data={customerList}
              defaultSortAsc
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              fixedHeader
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ImportDueList