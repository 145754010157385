import React, { Fragment, useState } from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { H1, Image, P } from "../../../AbstractElements";
import { AddCustomer, AddPackage, AddStbText, ContinueBilling, DefaultdashDiscription, GenerateRecurring, Welcome } from "../../../Constant";
import greeting from "../../../assets/images/Greetings/image 1.svg";

const ProfileGreeting = ({ companyName, checks, dataRecurring }) => {
    return (
        <Fragment>
            <Col lg={5}>
                <Card className="profile-greeting">
                    <CardBody>
                        <>
                            <div>
                                <H1>{Welcome} {companyName}</H1>
                                <P>{DefaultdashDiscription}</P>
                                {checks?.customers === 0 &&
                                    <Link to='/customers/customer-add' className="btn">
                                        {AddCustomer}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb === 0 &&
                                    <Link to='/customers/stb-modem-add' className="btn">
                                        {AddStbText}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb !== 0 && checks?.packages === 0 &&
                                    <Link to='/Services/packages-add' className="btn">
                                        {AddPackage}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb !== 0 && checks?.packages !== 0 &&
                                    <div>
                                        <Link to='/billing/invoice' className="btn">
                                            {ContinueBilling}<ArrowRight />
                                        </Link>
                                        {dataRecurring > 0 &&
                                            <Link to='/billing/recurring' className="btn mt-3">
                                                {GenerateRecurring} ({dataRecurring})<ArrowRight />
                                            </Link>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="greeting-img" >
                                <Image attrImage={{ className: "img-fluid", src: greeting, alt: "" }} />
                            </div >
                        </>



                    </CardBody>
                </Card>
            </Col>
        </Fragment >
    );
};

export default ProfileGreeting;