import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Media, Input } from "reactstrap";
import { Btn, H3, Breadcrumbs, H5 } from "../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../Layout/SpinnerLoader/index";
import {
    paymentMethod,
    addInvoicePayment,
    getCustomers,
    paymentSelectbox,
    paymentMethodRole,
    getCustomersRole,
    paymentSelectboxRole,
    addInvoicePaymentRole,
} from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from "react-toastify";
import Typeahead from "../../CommonElements/TypeAhead/index";
import DatePicker from 'react-datepicker';
import {
    PaymentMethod,
    DateText,
    Description,
    Save,
    Reference,
    Customer,
    Total,
    ConnectionType,
    totalPending,
    Walletbalance,
    Payfromwallet,
    quickPay,
    CollectAmount,
} from "../../Constant/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import SweetAlert from 'sweetalert2';

const schema = yup.object().shape({
    customer: yup.mixed().required().label('Customer'),
    amount: yup.string().required().label('Amount'),
    paymentMethod: yup.mixed().when('payment', {
        is: (value) => value == true,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required().label('payment required'),
    }),
});

const Quickpay = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    const authState = useSelector((state) => state.auth);
    const [startDate, setstartDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [Payment, setPayment] = useState([]);
    const [customerlist, setcustomerlist] = useState([]);

    const [selctedcust, setselctedcust] = useState('');
    const [isChecked, setisChecked] = useState(false);
    const [isAmount, setisAmount] = useState('');
    const [decimalWalletbalance, setdecimalWalletbalance] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.forEach((inputElement) => {
                    inputElement.removeEventListener('wheel', preventScrollEvent);
                });
            };
        });
    }, []);

    const type = [
        { value: '', label: "Any" },
        { value: "cable", label: "Cable" },
        { value: "broadband", label: "Broadband" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: 'sales_order', label: 'Sales Order' },
        { value: "others", label: "Others" },
    ];


    useEffect(() => {
        paymentData();
        setCustomer();
        selectType()
    }, [selctedcust]);


    const handleChangeDate = date => {
        setstartDate(date);
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setValue("payment", isChecked);

        setisChecked(isChecked);
        const amount_to_pay = getValues("amount");
        if (isChecked == true) {
            document.getElementById("amount").disabled = true;
            const walt_balnc = getValues("wallet");
            setValue("wallet_money", 1);

            if (parseFloat(isAmount) > parseFloat(walt_balnc)) {
                setValue("amount", walt_balnc);
            } else if (parseFloat(isAmount) <= parseFloat(walt_balnc)) {
                setValue("amount", amount_to_pay);
            }
        } else {
            setValue("amount", isAmount);
            document.getElementById("amount").disabled = false;
            setValue("wallet_money", null);
        }

    }


    const paymentData = async () => {
        let payment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentMethod : paymentMethodRole);
        if (payment.status === true) {
            const set_payment = payment.data.map((itemss) => ({
                value: itemss.id,
                label: itemss.name,
            }));
            setPayment(set_payment);
        } else if (payment.status >= 400 && payment.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (payment.status >= 500 && payment.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const setCustomer = async () => {
        const cust = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (cust.status === true) {
            const set_Customers = cust.data.map((itemss) => ({
                value: itemss.id,
                label: itemss.name,
            }));
            setcustomerlist(set_Customers);
        } else if (cust.status >= 400 && cust.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (cust.status >= 500 && cust.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const selectType = async (e) => {
        setspinnerTrans(true);
        const customer_id = Number(selctedcust);
        const selected_con = e ? e : "";
        const total_get_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentSelectbox + `?customer_id=${customer_id}&connection_type=${selected_con}` : paymentSelectboxRole + `?customer_id=${customer_id}&connection_type=${selected_con}`);

        if (total_get_api.status === true) {
            const single_stb = total_get_api.data;
            const pending_amount = single_stb.total_pending_amount.replace(/,/g, '');
            const total_payable = single_stb.total_payable.replace(/,/g, '');
            const wallet_balance = single_stb.wallet_amount.replace(/,/g, '');
            setValue("pending", pending_amount || 0);
            setValue("total", total_payable || 0);
            setdecimalWalletbalance(wallet_balance)
            setValue("wallet", wallet_balance || 0);
            setValue("amount", total_payable || 0);
            setisAmount(total_payable);
        } else if (total_get_api.status >= 400 && total_get_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (total_get_api.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false);

    }

    const handleSwitchToggle = async (data, subId) => {
        // event.preventDefault();
        const getAmount = getValues("amount")
        SweetAlert.fire({
            title: `<b>Amount </b>:  ${getAmount} `,
            text: 'Note : Once the payment is processed, it cannot be reversed, and no changes are allowed.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (!result.value) {
                return;
            }

            setLoading(true);
            const isCheckedwallet = getValues("wallet_money")

            const postRequest = {
                customer_id: data.customer.value,
                connection_type: data.type.value,
                amount: data.amount,
                date: data.date,
                payment_method: data.paymentMethod?.value || '',
                reference: data.reference,
                description: data.description,
                wallet_money: isCheckedwallet,
            };
            const addAddonResponse = await postClientUrlWithToken(
                authState.apiUrl, authState.userRole === 1 ? addInvoicePayment : addInvoicePaymentRole, postRequest
            );
            if (addAddonResponse.status === 200) {
                if (addAddonResponse.data.status === true) {
                    toast.success(addAddonResponse.data.message);
                    const paymentID = addAddonResponse.data.data;
                    navigate(`${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${paymentID}`);

                } else if (Array.isArray(addAddonResponse.data?.message)) {
                    const error = addAddonResponse.data.map((e) => e.message)
                    toast.error(error);
                } else {
                    toast.error(addAddonResponse.data.message);
                }
            } else if (addAddonResponse.status >= 400 && addAddonResponse.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (addAddonResponse.status >= 500 && addAddonResponse.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
            setLoading(false);
        })
    };

    return (
        <Fragment>
            {loading && <SpinnerLoader />}
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={quickPay} parent="Billing" title={quickPay} />
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(handleSwitchToggle)}>
                <Card>
                    <CardHeader className="pb-0">
                        <H3>{quickPay}</H3>
                    </CardHeader>
                    <CardBody>
                        <Row className="g-3 pt-2">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {Customer} <span className="requireStar">*</span>
                                </Label>
                                <Controller
                                    name="customer"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            placeholder="select..."
                                            options={customerlist}
                                            onChange={(value) => {
                                                setselctedcust(value.value);
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.customer?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {ConnectionType}
                                </Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    defaultValue={type[0]}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={type}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                const typevalue = value.value;
                                                selectType(typevalue);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {totalPending}
                                </Label>
                                <input
                                    className="form-control"
                                    id="validationCustom02"
                                    disabled
                                    type="number"
                                    {...register("pending", { required: true })}
                                />
                                <p className="text-danger">{errors.account?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {Total}
                                </Label>
                                <input
                                    className="form-control"
                                    id="validationCustom02"
                                    disabled
                                    type="number"
                                    {...register("total", { required: true })}
                                />
                                <p className="text-danger">{errors.amount?.message}</p>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {CollectAmount} <span className="requireStar">*</span>
                                </Label>
                                <input
                                    name="amount"
                                    className="form-control"
                                    id="amount"
                                    type="number"
                                    min="0"
                                    {...register("amount", { required: true })}
                                />
                                <p className="text-danger">{errors.amount?.message}</p>
                            </Col>
                            {isAmount > 0 && decimalWalletbalance > 0 && (
                                <Col md="6" className="">
                                    <Card>
                                        <Media className="d-flex p-20">
                                            <div className="form-check me-3">
                                                <Input className="wallet-checkbox" id="checkbox-primary-1" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} style={{ transform: 'scale(1.7)' }} />
                                                <Label for="checkbox-primary-1"></Label>
                                            </div>
                                            <Media body className='flex-grow-1'>
                                                <H3 attrH6={{ className: "mt-0 mega-title-badge" }}>{Walletbalance}<span className="badge badge-primary pull-right digits">{authState.currency} {decimalWalletbalance}</span></H3>
                                                <H5>{Payfromwallet}</H5>
                                            </Media>
                                        </Media>
                                    </Card>
                                </Col>
                            )}
                            {isChecked == false && (
                                <Col md="6">
                                    <Label className="form-label" for="validationCustom02">
                                        {PaymentMethod} <span className="requireStar">*</span>
                                    </Label>
                                    <Controller
                                        name="paymentMethod"
                                        control={control}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={Payment}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />

                                    <p className="text-danger">{errors.paymentMethod?.message}</p>
                                </Col>
                            )}
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="validationCustom02">
                                        {DateText}
                                    </Label>
                                    <Col xl='12' md="12" sm='12'>
                                        <DatePicker
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">
                                    {Reference}
                                </Label>
                                <input
                                    className="form-control"
                                    id="validationCustom02"
                                    type="text"
                                    {...register("reference", { required: true })}
                                />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea className="form-control" {...field} rows="3" />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup></FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">
                            {Save}
                        </Btn>
                    </CardBody>
                </Card>
            </Form>
        </Fragment>
    )
}

export default Quickpay