import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { Add, Customer, CustomerList, Search, Filter, Area, Status, Export, ArchiveCustomer, ArchiveCustomerLists } from '../../../Constant';
import { viewCustomer, viewCustomerRole, getArea, getAreaRole, viewArchiveCustomer, viewArchiveCustomerRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import moment from 'moment';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const ArchiveCustomerList = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [apiArea, setapiArea] = useState([]);
    const [startDate, endDate] = dateRange
    const isSearched = useRef(null);
    const isArea = useRef(null);
    const cust_status = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [filters, setFilters] = useState({
        searchKey: '',
        areaFilter: '',
        cust_status_Filter: '',
        start_date: '',
        end_date: ''
    });
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });

    const CustomerColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            width: '70px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row => row.subscriber_id,
            sortable: true,
            wrap: true,
            width: '100px',
            cell: row => (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.id}`}>
                    {row.subscriber_id}
                </Link> : row.subscriber_id,
        },
        {
            name: <H4>Name</H4>,
            id: 'name',
            selector: row => row.name,
            sortable: true,
            width: '140px',
            wrap: true
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Date,
            width: '100px',
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status}
                </span>
            ),
        },
        {
            name: <H4>Lco no</H4>,
            selector: row => row.Lco,
            id: 'lco_no',
            sortable: true,
            width: '100px',
            wrap: true,
        },
        {
            name: <H4>Mobile No</H4>,
            selector: row => row.Mobile,
            id: 'mobile',
            sortable: true,
            width: '160px',
        },
        {
            name: <H4>Area</H4>,
            selector: row => row.Area,
            id: 'area',
            sortable: true,
        },
        {
            name: <H4>Address</H4>,
            selector: row => row.Address,
            id: 'address',
            sortable: true,
            width: '160px',
            wrap: true,
        },
        {
            name: <H4>Route No</H4>,
            selector: row => row.Route,
            width: '110px',
        },
    ];

    const statusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "suspended", label: "Suspended" },
        { value: "archive", label: "Archive" },
    ];

    useEffect(() => {
        fetchCustomer(1);
        areaData();
    }, []);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchCustomer(currentPage, perPage, filters.searchKey, filters.start_date, filters.end_date, filters.areaFilter, filters.cust_status_Filter, column.id, newOrder);
    };


    const fetchCustomer = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', areaFilter = '', cust_status_Filter = '', sortBy = '', sortOrder = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewArchiveCustomer +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&area_id=${areaFilter}&status=${cust_status_Filter}&sort_by=${sortBy}&sort_order=${sortOrder}` :
            viewArchiveCustomerRole + `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&area_id=${areaFilter}&status=${cust_status_Filter}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    subscriber_id: <span>{e.subscriber_id}</span>,
                    Lco: e.lco_no,
                    name: e.name,
                    Mobile: "+" + e.country_code + " " + e.mobile,
                    Address: e.address.replace(/"/g, "" || ""),
                    Route: e.route_no,
                    Area: e.area_name,
                    Type: e.customer_type,
                    Date: e.join_date,
                    id: e.id,
                    Status: e.status
                }
            }))
            setTotalRows(response.data.total);
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportCustomerExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewCustomer + `?export=customer_data&search=${filters?.searchKey ? filters.searchKey : ''}
            &start_date=${filters?.start_date ? filters.start_date : ''}&end_date=${filters?.end_date ? filters.end_date : ''}
            &area_id=${filters?.areaFilter ? filters.areaFilter : ''}
            &status=${filters?.cust_status_Filter ? filters.cust_status_Filter : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewCustomerRole + `?export=customer_data&search=${filters?.searchKey ? filters.searchKey : ''}
            &start_date=${filters?.start_date ? filters.start_date : ''}&end_date=${filters?.end_date ? filters.end_date : ''}
            &area_id=${filters?.areaFilter ? filters.areaFilter : ''}
            &status=${filters?.cust_status_Filter ? filters.cust_status_Filter : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.data.map(({ subscriber_id, name, status, lco_no, mobile, area_name, address, route_no, country_code }) => ({ Subscriber_Id: subscriber_id, Name: name, Status: status, LCO_No: lco_no, Mobile: `+${country_code} ${mobile}`, Area: area_name, Address: address.replace(/"/g, ''), Route_No: route_no }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Customer Data');
            XLSX.writeFile(wb, 'customer_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportCustomerPdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            viewCustomer + `?export=customer_data&search=${filters?.searchKey ? filters.searchKey : ''}
            &start_date=${filters?.start_date ? filters.start_date : ''}&end_date=${filters?.end_date ? filters.end_date : ''}
            &area_id=${filters?.areaFilter ? filters.areaFilter : ''}
            &status=${filters?.cust_status_Filter ? filters.cust_status_Filter : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : viewCustomerRole + `?export=customer_data&search=${filters?.searchKey ? filters.searchKey : ''}
            &start_date=${filters?.start_date ? filters.start_date : ''}&end_date=${filters?.end_date ? filters.end_date : ''}
            &area_id=${filters?.areaFilter ? filters.areaFilter : ''}
            &status=${filters?.cust_status_Filter ? filters.cust_status_Filter : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.data.map(({ subscriber_id, name, status, lco_no, mobile, area_name, address, route_no, country_code }, index) => ({ Sl: index + 1, subscriber_id, name, status, lco_no, mobile: `+${country_code} ${mobile}`, area_name, address, route_no }));
        if (response.status === true) {
            const doc = new jsPDF();
            const heading = "Customer's list";
            doc.setFontSize(18);
            doc.setFont("helvetica", "bold");
            doc.text(heading, 14, 22);

            const columns = ["Sl", "Subscriber Id", "Name", "Status", "LCO No", "Mobile", "Area", "Address", "Route No"];
            const rows = dataToExport.map(({ subscriber_id, name, status, lco_no, mobile, area_name, address, route_no }, index) => [index + 1, subscriber_id, name, status, lco_no, mobile, area_name, address, route_no]);
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 27
            });
            doc.save("customer_list.pdf");
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };


    const handlePageChange = page => {
        fetchCustomer(page, perPage, filters.searchKey, filters.start_date, filters.end_date, filters.areaFilter, filters.cust_status_Filter, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomer(page, newPerPage, filters.searchKey, filters.start_date, filters.end_date, filters.areaFilter, filters.cust_status_Filter, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const handleFilter = async (e) => {
        e.preventDefault();
        const searchKey = isSearched.current.value;
        const areaFilter = isArea.current.value;
        const cust_status_Filter = cust_status.current.value;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            searchKey,
            areaFilter,
            cust_status_Filter,
            start_date,
            end_date
        });
        fetchCustomer(currentPage, perPage, searchKey, start_date, end_date, areaFilter, cust_status_Filter, sortConfig.column, sortConfig.order);
    };

    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={ArchiveCustomer} parent="Customers" title={ArchiveCustomer} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{ArchiveCustomerLists}</H3>
                    <div style={{ display: 'flex' }}>
                        <CardBody className="dropdown-basic" style={{ borderRadius: '5px' }}>
                            <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportCustomerExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportCustomerPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>
                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input className="form-control" placeholder={Search} type="text" ref={isSearched}
                                    />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element" >
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'
                                        />
                                    </div>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isArea}>
                                        <option value="">{Area}</option>
                                        {apiArea.map((values, index) => (
                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={cust_status}>
                                        <option value="">{Status}</option>
                                        {statusOptions.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>

                    <div className="order-history table-responsive">
                        <DataTable
                            columns={CustomerColumns}
                            data={customerLists}
                            // progressPending={loading}
                            pagination
                            paginationServer
                            defaultSortAsc
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ArchiveCustomerList;