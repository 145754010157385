import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Media, Label, Input, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../Utils/restUtils';
import { Add, Search, Filter, Header, HeaderList } from '../../Constant';
import { useSelector } from 'react-redux';
import { deleteHeaders, deleteHeadersRole, statusHeaders, statusHeadersRole, getHeaders, getHeaderRole } from '../../CommonUrl';
import { toast } from 'react-toastify';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const HeaderContainer = () => {
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const toggleStyleDelete = { padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' };
    const [headerList, setProviderList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchKeyword === '') {
            setLoading(true);
            headerData(1);
        }
    }, [searchKeyword]);

    const HeaderColumns = [
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,
            width: '160px',
        },
        {
            name: <H4>Title</H4>,
            selector: row => row.Title,
            sortable: true,
            width: '210px',
        },
        {
            name: <H4>Description</H4>,
            selector: row => row.Description,
            sortable: true,
            width: '160px',
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: true,
        },
    ];

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        headerData();
    };

    const handleSwitchToggle = async (event, headerId) => {
        const checked = event.target.checked;
        let reqSwitch = { header_id: headerId, status: checked ? 'inactive' : 'active' };

        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusHeaders : statusHeadersRole, reqSwitch);
        if (statusChange.data.status === true) {
            toast.success(statusChange.data.message);
        } else {
            toast.error(statusChange.data.message);
        }
    }

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/Header/Header-edit?id=${row.id}`;
            navigate(editFormURL);
        };
        const deleteHeaderList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                if (result.value) {
                    const deleteRes = { header_id: row.id };
                    let headerDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteHeaders : deleteHeadersRole, deleteRes);
                    if (headerDelete.status === 200) {
                        if (headerDelete.data && headerDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            headerData();
                        }
                    } else if (headerDelete.status >= 400 && headerDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (headerDelete.status >= 500 && headerDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('edit-add-on')) &&
                        <DropdownItem style={toggleStyle} onClick={handleEditClick}>Edit</DropdownItem>
                    }
                    {(authState.userRole === 1 || authState.permissions.includes('remove-add-on')) &&
                        <DropdownItem style={toggleStyleDelete} onClick={deleteHeaderList}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const headerData = async () => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getHeaders : getHeaderRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            if (Array.isArray(data.data)) {
                const filteredHeader = data.data.filter((item) => {
                    const nameMatch = item.title && item.title.toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch;
                });
                setProviderList(filteredHeader.map((e, index) => {
                    return {
                        Si: index + 1,
                        id: e.id,
                        Title: e.title,
                        Description: e.description,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active"}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        action: <ActionCell row={{ id: e.id, header_id: e.id }} />
                    }
                }))
            }
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Header} parent="Expenses" title={Header} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{HeaderList}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/Header/Header-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control custom-search-input" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange} />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={headerList}
                            columns={[
                                {
                                    name: <H4>Sl</H4>,
                                    selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                    width: '60px',
                                    sortable: false, // Disable sorting for SI
                                },
                                ...HeaderColumns // All other columns remain the same
                            ]}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default HeaderContainer;

