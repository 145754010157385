import { Total, Item, Amount, Quantity, DiscountT, } from '../../Constant';
import { P } from '../../AbstractElements';
import { Col, Label, Row, Container, CardBody, Card, Nav, NavItem, NavLink, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { changeCompany, getCompany, getPackageDet } from '../../CommonUrl';
import { postClientUrlWithToken } from '../../Utils/restUtils';
import InvoiceTotal from './InvoiceTotal';
import Transparentspinner from "../Common/Loader/Transparentspinner";
import InvoiceHeader from './InvoiceHeader';
import HandleHomePageError from '../Common/ErrorImport/SignError';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ItemDescription = React.forwardRef((props, ref) => {
    const authState = useSelector((state) => state.auth);
    const invoiceDetail = props.idValue;
    const statusColor = props.statusColor;
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const navigate = useNavigate();
    const [primarycolorTab, setprimarycolorTab] = useState('1');
    const [company, setCompany] = useState('');
    const [importDrpodown, setimportDrpodown] = useState(false);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        companyData();
    }, []);

    const toggleImport = () => setimportDrpodown(!importDrpodown);
 
    const companyData = async () => {
        const data = await postClientUrlWithToken(authState.apiUrl, getCompany);
        if (data.status === 200) {
            const companyData = data.data.data;
            if (companyData.length > 0) {
                setCompany(companyData);
            } else {
                setCompany("");
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const companyChangeData = async (id) => {
        setloading(true);
        const data = await postClientUrlWithToken(authState.apiUrl, changeCompany, { company_id: id });
        if (data.status === 200) {
            if (data.data.status === true) {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`;
                const newActiveCompany = company.find(company => company.id === id);
                if (newActiveCompany) {
                    toast.success(data.data.message)
                    companyData();
                }
            } else {
                toast.error(data.data.message);
                setloading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setloading(false);
    };

    const printSection = () => {
        return (
            <div ref={ref}>
                <div className='invoice invoice-print-styling'>
                    <Row>
                        <div className='mt-5'>
                            <InvoiceHeader prop1={invoiceDetail} />
                            <div className="table-responsive invoice-table" id="table">
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr>
                                            <th className="item text-left">
                                                {Item}
                                            </th>
                                            <th className="Rate text-end">
                                                {Quantity}
                                            </th>
                                            <th className="Hours text-end">
                                                Unit Price
                                            </th>
                                            <th className="Hours text-end">
                                                {DiscountT}
                                            </th>
                                            <th className="subtotal text-end">
                                                {Total}
                                            </th>
                                        </tr>
                                        {invoiceDetail.order_item?.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <Label className='mb-2'>{item.name}</Label>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>{authState.currency} {item.unit_price}</P>
                                                    </td>
                                                    <td className=' text-end'>
                                                        <P attrPara={{ className: 'itemtext digits' }}>{item.discount ?? 0}</P>
                                                    </td>
                                                    <td className=' text-end' >
                                                        <P attrPara={{ className: 'itemtext digits' }}>{authState.currency} {invoiceDetail.sub_total}</P>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <P attrPara={{ style: { marginTop: '20px', marginLeft: '20px' } }}><strong>Note:</strong> The monthly base charge is {authState.currency} 200.</P>
                            </div>
                            <InvoiceTotal item={invoiceDetail} />
                        </div>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <Container>
            {loading && <Transparentspinner />}
            <Row>
                <Col sm="12" xl="6 xl-100">
                    <Card>
                        <CardBody>
                            <div style={{ display: 'flex', float: 'right' }}>
                                <Dropdown className='ms-2' isOpen={importDrpodown} toggle={toggleImport}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        Change Company <i className="icofont icofont-arrow-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        {Array.isArray(company) && company.length > 0 ? (
                                            company.map((item, index) => (
                                                <DropdownItem key={index} style={toggleStyle} onClick={() => companyChangeData(item.id)}>{item.name}</DropdownItem>
                                            ))
                                        ) : <p>Loading...</p>
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Nav className="nav-primary" tabs style={{ marginTop: '10px' }}>
                                <NavItem style={{ display: 'flex' }}>
                                    <NavLink className={primarycolorTab === '1' ? 'active' : ''} style={{
                                        background: primarycolorTab === '1' ? statusColor : 'transparent',
                                    }}
                                        onClick={() => setprimarycolorTab('1')}>
                                        {invoiceDetail.payment_status}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={primarycolorTab}>
                                <TabPane tabId="1">
                                    {printSection()}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
});

export default ItemDescription;