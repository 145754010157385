import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Table } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Category, InvoiceDate, Customer, DueDate, Save, Notes, Order, AddOrder } from "../../../Constant";
import { getCustomers, addSalesOrder, getcategoryincome, getitemSelect, getitemdeatils, statusTax, getitemSelectRole, statusTaxRole, getcategoryincomeRole, getCustomersRole, getitemdeatilsRole, addSalesOrderRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from "react-toastify";
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  category: yup.mixed().required().label('Category'),
  items: yup.array().of(
    yup.object().shape({
      name: yup.mixed().required('Item name is required'),
      tax: yup.mixed().required('Tax is required'),
    })
  )
})

const OrderForm = () => {
  const [spinnerTrans, setspinnerTrans] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,  // Add setValue from useForm
    trigger,
    setError,   // Add setError from useForm
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: { rowsData: [] }, resolver: yupResolver(schema) });
  const authState = useSelector((state) => state.auth);
  const currency = authState.currency;
  const [customer, setCustomer] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [form, setForm] = useState([{ price: 0, qty: 1, discount: 0, tax: 0, total: 0, totaltax: 0, itemTotal: 0, stock: null }]);
  const [totalDiscount, settotalDiscount] = useState(0)
  const [totalTaxamount, settotalTaxamount] = useState(0);
  const [grandTotl, setgrandTotl] = useState(0)
  const [itemsoptions, setitemsoptions] = useState([]);
  const [itemTotal, setitemTotal] = useState(0)
  const [taxOption, settax] = useState([]);
  const [discountError, setDiscountError] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const totalDiscount = form.reduce((acc, curr) => acc + parseFloat(curr.discount), 0);
    const grandTotal = form.reduce((acc, curr) => acc + parseFloat(curr.total), 0);
    const totaltax = form.reduce((acc, curr) => acc + parseFloat(curr.totaltax), 0);
    const itemTotal = form.reduce((acc, curr) => acc + parseFloat(curr.qty * curr.price), 0);
    setitemTotal(parseFloat(itemTotal).toFixed(authState.decimal));
    settotalDiscount(parseFloat(totalDiscount).toFixed(authState.decimal));
    setgrandTotl(parseFloat(grandTotal).toFixed(authState.decimal));
    settotalTaxamount(parseFloat(totaltax).toFixed(authState.decimal));
  }, [form])

  useEffect(() => {
    customerData();
    categoryData();
    itemcategory();
    taxSelect();
  }, []);

  useEffect(() => {
    setCustomerOption(
      customer.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
    setCategoryOption(
      category.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    );
  }, [customer, category]);

  const handleProductSelect = () => {
    setForm([...form, { type: '', package_id: '', name: '', price: 0, qty: 1, discount: 0, discount_type: 'cash', tax: 0, tax_group_id: "", itemTotal: 0, totaltax: 0, total: 0, grandTotal: 0, stock: null }]);
  };

  const handleApiError = (status) => {
    const errorPage = status >= 400 && status <= 405 ? 'error-page1' : status >= 500 && status <= 505 ? 'error-page4' : 'error-page3';
    navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
  };

  const handleinvoiceChangeDate = date => {
    setInvoiceDate(date);
    setDueDate(date);
  };

  const handleDueChangeDate = date => {
    setDueDate(date);
  };

  const itemcategory = async () => {
    setspinnerTrans(true);
    const item_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getitemSelect : getitemSelectRole);
    if (item_api.status === true) {
      const data = item_api.data;
      const itemslist = data.map((items) => ({ value: items.id, label: items.name, tax_id: items.tax_group_id }));
      setitemsoptions(itemslist);
    } else {
      handleApiError(data.status);
    }
    setspinnerTrans(false);
  }

  const taxSelect = async () => {
    setspinnerTrans(true);
    const item_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
    if (item_api.status === true) {
      const data = item_api.data;

      const itemslist = data.map((items) => ({ value: items.id, label: items.title, percentage: items.tax_percentage }));
      settax(itemslist);
    } else {
      handleApiError(data.status);
    }
    setspinnerTrans(false);
  }

  const categoryData = async () => {
    const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryincome : getcategoryincomeRole);
    if (data.status === true) {
      setCategory(data.data);
    } else {
      handleApiError(data.status);
    }
  };

  const customerData = async () => {
    const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (data.status === true) {
      setCustomer(data.data);
    } else {
      handleApiError(data.status);
    }
  };


  const handleItemSelect = async (index, selected) => {
    if (!selected) {
      return;
    }
    setspinnerTrans(true);
    const selectedName = selected.label;
    const item_details = await getClientUrlDataToken(authState.apiUrl,
      authState.userRole === 1 ? getitemdeatils + `?item_name=${selectedName}` : getitemdeatilsRole + `?item_name=${selectedName}`
    );
    const i = item_details.data;
    const stock_count = i?.stock ?? 0;
    const i_price = i?.price ?? 0;
    const find_tax = taxOption?.find((tax) => tax?.value === i?.tax_group_id);
    const updatedForm = {
      tax_group_id: find_tax?.value || '',
      tax: find_tax?.percentage || 0,
      name: selectedName,
      qty: 1,
      stock: stock_count,
      price: i_price || 0,
      discount: 0,
      itemTotal: Math.max((i_price || 0) * 1 - 0, 0),
      totaltax: Math.max(((find_tax?.percentage || 0) / 100) * (i_price || 0), 0),
      total: parseFloat(Math.max(((i_price || 0) * 1 + ((find_tax?.percentage || 0) / 100) * (i_price || 0)), 0)).toFixed(authState.decimal),
    };

    setForm((prevForm) => {
      const updatedFormArray = [...prevForm];
      updatedFormArray[index] = updatedForm;
      return updatedFormArray;
    });
    if (!find_tax) {
      setError(`items[${index}].tax`, {
        type: 'manual',
        message: 'Tax is required and must be selected.',
      });
    } else {
      setValue(`items[${index}].tax`, find_tax?.percentage || 0);
      clearErrors(`items[${index}].tax`);
    }

    trigger(`items[${index}].tax`);

    setspinnerTrans(false);
  };


  const handleQuantityChange = (index, newQty) => {
    const parsedQty = parseFloat(newQty) || 0;
    setForm((prev) => {
      const updatedForm = [...prev];
      const currentRow = {
        ...updatedForm[index],
        qty: parsedQty
      };
      Object.assign(currentRow, calculateTotals(currentRow));
      updatedForm[index] = currentRow;
      return updatedForm;
    });
  };


  const calculateTotals = (row) => {
    const itemTotal = Math.max((row.price * row.qty) - row.discount, 0);
    const totaltax = (row.tax / 100) * itemTotal;
    const total = parseFloat(itemTotal + totaltax).toFixed(authState.decimal);
    return { itemTotal, totaltax, total };
  };

  const handleDiscount = (index, newDiscount) => {
    const parsediscount = parseFloat(newDiscount) || 0;
    setForm((prev) => {
      const updatedForm = [...prev];
      const currentRow = { ...updatedForm[index], discount: parsediscount };
      Object.assign(currentRow, calculateTotals(currentRow));
      setDiscountError(parseFloat(parsediscount) >= parseFloat(currentRow.price));
      updatedForm[index] = currentRow;
      return updatedForm;
    });
  };

  const handleunitCostChange = (index, amount) => {
    const parseUnitcost = parseFloat(amount) || 0;

    setForm((prev) => {
      const updatedForm = [...prev];
      const currentRow = { ...updatedForm[index], price: parseUnitcost };
      Object.assign(currentRow, calculateTotals(currentRow));
      updatedForm[index] = currentRow;
      return updatedForm;
    });
  };

  const handleTaxSelect = (index, tax) => {
    const parsedtax = parseFloat(tax.percentage) || 0;

    setForm((prev) => {
      const updatedForm = [...prev];
      const currentRow = {
        ...updatedForm[index],
        tax: parsedtax,
        tax_group_id: tax.value,
      };
      Object.assign(currentRow, calculateTotals(currentRow));
      updatedForm[index] = currentRow;
      return updatedForm;
    });
  };


  const handleRemove = (index) => {
    const updatedForm = [...form];
    updatedForm.splice(index, 1);
    setForm(updatedForm);
  };


  const onSubmit = async (data) => {
    setspinnerTrans(true);
    const postRequest = {
      customer_id: data.customer.value,
      category: data.category.value,
      due_date: moment(dueDate).format("DD-MM-YYYY"),
      invoice_date: moment(invoiceDate).format("DD-MM-YYYY"),
      comments: data.comments ?? '',
      items: form,
    }
    const addPaymentResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addSalesOrder : addSalesOrderRole, postRequest);
    if (addPaymentResponse.data.status === true) {
      toast.success(addPaymentResponse.data.message)
      const id = addPaymentResponse?.data?.data;
      const cust_id = data.customer.value;
      navigate(`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${id}&customer_id=${cust_id}`);

    } else {
      toast.error(addPaymentResponse.data.message);
      setspinnerTrans(false);

    }
    setspinnerTrans(false);
  };

  return (
    <Fragment>
      {spinnerTrans && <Transparentspinner />}
      <Breadcrumbs mainTitle={Order} parent="Billing" title={Order} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{AddOrder}</H3>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={customerOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.customer?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">
                  {Category} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={categoryOption}
                      onChange={(selected) => {
                        field.onChange(selected);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.category?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label"> {InvoiceDate} </Label>
                  <DatePicker
                    style={{ padding: '12px 100px' }}
                    className="form-control "
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    selected={invoiceDate}
                    onChange={handleinvoiceChangeDate}
                    required
                  />
                  <p className="text-danger">{errors.invoicedate?.message}</p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label"> {DueDate} </Label>
                  <DatePicker
                    style={{ padding: '12px 100px' }}
                    className="form-control "
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    selected={dueDate}
                    onChange={handleDueChangeDate}
                    required
                  />
                  <p className="text-danger">{errors.duedate?.message}</p>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label className="form-label pt-3">{Notes}</Label>
                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <textarea
                      className="form-control"
                      {...field}
                      rows="3"
                      maxLength={250}
                      {...register("Notes", { required: true })}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="form-label pt-3">
              <Fragment>
                <Row>
                  {form.length > 0 && (
                    <Table className="table border mt-4 sales-order-table">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th className="text-center">Qty</th>
                          <th className="text-end">Unit price</th>
                          <th>Discount</th>
                          <th>Item total</th>
                          <th>Tax</th>
                          <th className="text-end">Total</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.map((item, index) => (
                          <tr className="" key={index}>
                            <td className="col-sm-12 col-md-2">
                              <label>Item</label>
                              <Controller
                                name={`items[${index}].name`}
                                control={control}
                                rules={{ required: "Item is required" }}
                                render={({ field }) => (
                                  <CreatableSelect
                                    {...field}
                                    options={itemsoptions}
                                    value={itemsoptions.find(option => option.label === item.name)}
                                    onChange={(selected) => {
                                      field.onChange(selected);
                                      handleItemSelect(index, selected);
                                    }}
                                    isClearable
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        height: 45,
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 1000,
                                      }),
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#ffffff',
                                        primary: '#534686',
                                        primary50: 'rgba(83, 70, 134, 0.3)',
                                      },
                                    })}
                                  />
                                )}
                              />
                              {item.stock != null && (
                                <div className="pt-1">
                                  <b>In stock: <span className="text-danger">{item.stock}</span></b>
                                </div>
                              )}
                              {errors.items?.[index]?.name && (
                                <span className="text-danger">{errors.items[index].name.message}</span>
                              )}
                            </td>
                            <td className="col-sm-12 col-md-1">
                              <label>Qty</label>
                              <input
                                name={`items[${index}].qty`}
                                placeholder='Qty'
                                value={item.qty}
                                type="number"
                                min={1}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    index,
                                    e.target.value,
                                  )}
                                className="form-control quantity"
                              />
                              {errors.items?.[index]?.qty && (
                                <span className="text-danger">{errors.items[index].qty.message}</span>
                              )}
                            </td>
                            <td className="col-sm-12 col-md-2 itemWidth">
                              <label>Price</label>
                              <input
                                name={`items[${index}].price`}
                                placeholder='Unit cost'
                                value={item.price}
                                min={0}
                                type="number"
                                onChange={(e) => handleunitCostChange(index, e.target.value)}
                                className="form-control price"
                              />
                            </td>
                            <td className="col-sm-12 col-md-1 itemWidth">
                              <label>Discount</label>
                              <input
                                name={`items[${index}].discount`}
                                type="number"
                                min={0}
                                placeholder='Discount'
                                value={item.discount}
                                onChange={(e) => handleDiscount(index, e.target.value)}
                                className="form-control discount"
                                disabled={item.price <= 0}
                              />
                              {discountError === true && (
                                <div className="pt-1">
                                  <span className="text-danger">Discount exceed</span>
                                </div>
                              )}
                            </td>
                            <td className="col-sm-12 col-md-1 itemWidth">
                              <label>Item total</label>
                              <input
                                name={`items[${index}].itemTotal`}
                                placeholder='Item total'
                                value={`${currency} ${item.itemTotal}`}
                                className="form-control item-total"
                                readOnly
                                type="text"
                              />
                            </td>
                            <td className="col-sm-12 col-md-2">
                              <label>Tax</label>
                              <Controller
                                name={`items[${index}].tax`}
                                control={control}
                                rules={{ required: "Tax is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={taxOption}
                                    value={taxOption.find(options => options?.value === item.tax_group_id) || ''}
                                    onChange={(selected) => {
                                      field.onChange(selected);
                                      handleTaxSelect(index, selected);
                                    }}
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        height: 45,
                                      }),
                                      menu: (baseStyles) => ({
                                        ...baseStyles,
                                        zIndex: 1000,
                                      }),
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(83, 70, 134, 0.474)',
                                        primary: '#534686',
                                        primary50: 'rgba(83, 70, 134, 0.3)',
                                      },
                                    })}
                                  />
                                )}
                              />
                              {errors.items?.[index]?.tax && (
                                <span className="text-danger">{errors.items[index].tax.message}</span>
                              )}
                            </td>
                            <td className="col-sm-12 col-md-2">
                              <label>Total</label>
                              <input
                                name={`items[${index}].total`}
                                placeholder='Total'
                                value={`${currency} ${item.total}`}
                                className="form-control grand-total"
                                readOnly
                              />
                            </td>
                            <td className="">
                              <button onClick={() => handleRemove(index)} type="button" className="btn btn-danger" disabled={form.length <= 1}><i className="fa fa-trash-o"></i></button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="">
                          <th className="fs-5">Total</th>
                          <th></th>
                          <th className="">Unit cost : <span className="">{currency} {itemTotal}</span></th>
                          <th className="">Discount : <span>{currency} {totalDiscount}</span></th>
                          <th></th>
                          <th>Tax : <span>{currency} {totalTaxamount}</span></th>
                          <th className=" fs-5"><span className="fw-bolder">{currency} {grandTotl}</span></th>
                          <th><button type="button" className="btn btn-success " onClick={handleProductSelect}><i className="fa fa-plus-circle"></i></button></th>
                        </tr>
                      </tfoot>
                    </Table>
                  )}

                </Row>
              </Fragment>
            </Row>
            <Btn attrBtn={{ color: "primary", className: "mt-3" }} type="submit"> {Save} </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default OrderForm;
