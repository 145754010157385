import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Btn } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { deactivateDetails, deactivateDetailsRole, postReconnectStb, postReconnectStbRole } from '../../../CommonUrl';
import { toast } from 'react-toastify';
import moment from "moment";
import DatePicker from 'react-datepicker';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const ReconnectModal = ({ reconnectValue, stbLists, handleModalReconnect, perPagelist, currentPageNo }) => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const dataValue = reconnectValue;
    const { handleSubmit } = useForm();
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [personalDetails, setPersonalDetails] = useState({});
    const [reconnectDate, setReconnectDate] = useState(new Date());

    useEffect(() => {
        getDeactivateDetails();
    }, [])

    const StyledDatePicker = styled(DatePicker)`
    .react-datepicker__navigation-icon--next {
        top: 0px !important;
        background-color : #ffffff
    } `

    const getDeactivateDetails = async () => {
        setspinnerTrans(true)
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? deactivateDetails + `?stb_id=${dataValue.id}` : deactivateDetailsRole + `?stb_id=${dataValue.id}`);
        if (data.status === true) {
            setPersonalDetails(data.data);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false);
    }

    const handleChangeDate = date => {
        setReconnectDate(date);
    };

    const onSubmit = async (data) => {
        setspinnerTrans(true)
        const postRequest = {
            stb_id: dataValue.id,
            status_date: moment(reconnectDate).format("DD-MM-YYYY"),
        }
        const addDisconnectResponce = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? postReconnectStb : postReconnectStbRole, postRequest);
        if (addDisconnectResponce.status === 200) {
            if (addDisconnectResponce.data.status === true) {
                toast.success(addDisconnectResponce.data.message);
                handleModalReconnect();
                stbLists(currentPageNo, perPagelist, '', '', '', '', '');
            } else {
                toast.error(addDisconnectResponce.data.message);
                handleModalReconnect();
                stbLists(currentPageNo, perPagelist, '', '', '', '', '');
                setspinnerTrans(false)
            }
        } else if (addDisconnectResponce.status >= 400 && addDisconnectResponce.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addDisconnectResponce.status >= 500 && addDisconnectResponce.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    }

    return (
        <>
            {spinnerTrans && <Transparentspinner />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="activity-media ">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Customer</h3>
                                    <span>
                                        <h4>{personalDetails.customer_name}<br />{personalDetails.customer_address}</h4>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Mac id/serial no</h3>
                                    <span><h4>{personalDetails.mac_id}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Install date</h3>
                                    <span><h4>{personalDetails.install_date}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>STB modem status</h3>
                                    <span><h4>{personalDetails.stb_status}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Smart card no/VC no</h3>
                                    <span><h4>{personalDetails.stb_no}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className="flex-grow-1">
                                    <h3>Reconnection date</h3>
                                    <Col xl='12' md="12" sm='12'>
                                        <StyledDatePicker
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            selected={reconnectDate}
                                            onChange={handleChangeDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-11'>
                            <div className="d-flex" >
                                <div className="flex-grow-1">
                                    <h3>Note:</h3>
                                    <span><h5 >Reconnect stb_Your STB is currently disconnected. Please reconnect to resume services. Press 'Reconnect' to proceed or contact support for assistance.</h5></span>
                                    <div className=' pt-3'>
                                        <span className='ps-2 spinner-enable-stb'>
                                            <Btn attrBtn={{ color: "primary", className: 'bttn' }} type="submit">
                                                Reconnect
                                            </Btn>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ReconnectModal