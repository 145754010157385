import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Col, Card, CardHeader, CardBody, Form, Row, Label } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, OfflinePayment, OfflinePaymentMethod, Search, Filter, DefaultPaymentMethod, Status, Submit, PaymentMethod, CustomerApp, POSMachine, AgentCollect } from '../../../Constant';
import { useSelector } from 'react-redux';
import { deleteOfflinePayment, deleteOfflinePaymentRole, editDefaultPayment, editDefaultPaymentRole, getDefaultPaymentMethod, paymentMethod, paymentMethodRole, postDefaultPayment, postDefaultPaymentRole, viewPayment, viewPaymentRole } from '../../../CommonUrl';
import { PaymentColumns } from '../../../Data/MockTable/SettingsTableHeader';
import Transparentspinner from '../../Common/Loader/Transparentspinner'
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Typeahead from '../../../CommonElements/TypeAhead';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    customerPayment: yup.mixed().required().label('Payment Method').test('required-value', 'Payment Method is required', (value) => {
        return value && value.value !== '';
    }),
    posMachine: yup.mixed().required().label('Payment Method').test('required-value', 'Payment Method is required', (value) => {
        return value && value.value !== '';
    }),
    agentCollect: yup.mixed().required().label('Payment Method').test('required-value', 'Payment Method is required', (value) => {
        return value && value.value !== '';
    }),
});


const PaymentMethodContainer = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [paymentList, setPaymentList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [Payment, setPayment] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
            offlinePaymentData();
            paymentData();
        }
    }, [searchKeyword]);

    useEffect(() => {
        fetchDefaultPaymentData();
    }, [Payment])

    const paymentData = async () => {
        const payment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentMethod : paymentMethodRole);
        if (payment.status === true) {
            const set_payment = payment.data.map((items) => ({
                value: items.id,
                label: items.name,
            }));
            setPayment(set_payment);
        } else {
            const errorPage = payment.status >= 400 && payment.status <= 405 ? 'error-page1'
                : payment.status >= 500 && payment.status <= 505 ? 'error-page4'
                    : 'error-page3';
            navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
        }
    };


    const fetchDefaultPaymentData = async () => {
        const Defpayment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getDefaultPaymentMethod : paymentMethodRole);
        if (Defpayment.status === true && Defpayment.data) {
            const { agent_collection, customer_app, pos_machine } = Defpayment.data;
            const isEditMode = agent_collection || customer_app || pos_machine ? true : false;
            setIsEditMode(isEditMode);
            const custValue = Payment.find((option) => option.value === customer_app) || { value: '', label: 'Select...' };
            setValue("customerPayment", { value: custValue?.value, label: custValue?.label });
            const posValue = Payment.find((option) => option.value === pos_machine) || { value: '', label: 'Select...' };
            setValue("posMachine", { value: posValue?.value, label: posValue?.label });
            const agentValue = Payment.find((option) => option.value === agent_collection) || { value: '', label: 'Select...' };
            setValue("agentCollect", { value: agentValue?.value, label: agentValue?.label });
        } else {
            const errorPage = Defpayment.status >= 400 && Defpayment.status <= 405 ? 'error-page1'
                : Defpayment.status >= 500 && Defpayment.status <= 505 ? 'error-page4'
                    : 'error-page3';
            navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
        }
    }


    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        offlinePaymentData();
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/settings/payment-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deletePaymentList = (userId) => {
        SweetAlert.fire({
            title: 'Confirm deletion?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true)
            if (result.value) {
                const deleteRes = { payment_method_id: userId }
                const paymentMethodDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteOfflinePayment : deleteOfflinePaymentRole, deleteRes);
                if (paymentMethodDelete.status === 200) {
                    if (paymentMethodDelete.data.status === true) {
                        SweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        offlinePaymentData();
                    } else {
                        SweetAlert.fire(
                            'Error!',
                            paymentMethodDelete.data.message,
                            'error'
                        );
                        offlinePaymentData();
                    }
                    setLoading(false)
                } else {
                    const errorPage = paymentMethodDelete.status >= 400 && paymentMethodDelete.status <= 405 ? 'error-page1'
                        : paymentMethodDelete.status >= 500 && paymentMethodDelete.status <= 505 ? 'error-page4'
                            : 'error-page3';
                    navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
                }
            }
        });
    };

    const offlinePaymentData = async () => {
        setLoading(true)
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPayment : viewPaymentRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setLoading(true);

            const filteredPayment = data.data?.filter((item) => {
                const nameMatch = item.name?.toLowerCase().includes(searchKeyword.toLowerCase());
                const codeMatch = item.code?.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                return nameMatch || codeMatch;
            }) || [];
            setPaymentList(filteredPayment.map((e, index) => ({
                Si: index + 1,
                Name: e.name,
                Code: e.code,
                Order: e.order,
                action: (
                    <div>
                        <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit</Btn>
                        <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => deletePaymentList(e.id) }}>Delete</Btn>
                    </div>
                ),
            })));
            setLoading(false);
        } else {
            const errorPage = data.status >= 400 && data.status <= 405 ? 'error-page1'
                : data.status >= 500 && data.status <= 505 ? 'error-page4'
                    : 'error-page3';
            navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
        }
    };

    const onSubmit = async (value) => {
        setLoading(true);
        const postRequest = {
            customer_app: value.customerPayment?.value,
            pos_machine: value.posMachine?.value,
            agent_collection: value.agentCollect?.value,
        };
        const apiToCall = isEditMode ? editDefaultPayment : postDefaultPayment;
        const apiToCallRole = isEditMode ? editDefaultPaymentRole : postDefaultPaymentRole;
        const editDefPaymentResponse = await postClientUrlWithToken(authState.apiUrl,
            authState.userRole === 1 ?
                apiToCall : apiToCallRole,
            postRequest
        );
        if (editDefPaymentResponse.status === 200) {
            if (editDefPaymentResponse.data.status === true) {
                toast.success(editDefPaymentResponse.data.message);
                fetchDefaultPaymentData();
            } else {
                toast.error(editDefPaymentResponse.data.message);
            }
        } else {
            const errorPage = editDefPaymentResponse.status >= 400 && editDefPaymentResponse.status <= 405 ? 'error-page1'
                : editDefPaymentResponse.status >= 500 && editDefPaymentResponse.status <= 505 ? 'error-page4'
                    : 'error-page3';
            navigate(`${process.env.PUBLIC_URL}/pages/error/${errorPage}`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={OfflinePayment} parent="Settings" title={OfflinePayment} />
            <Row>
                <Col xl="3" className="box-col-25">
                    <Card className='cardWidth'>
                        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <H3>{DefaultPaymentMethod}</H3>
                        </CardHeader>
                        <CardBody>
                            <span className=''>
                                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="gap-sm-3 gap-md-1 mb-3 filterGap">
                                        <Label className="form-label" for="validationCustom02">
                                            {CustomerApp} <span className="requireStar">*</span>
                                        </Label>
                                        <Controller
                                            name="customerPayment"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={Payment}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        <p className="text-danger">{errors.customerPayment?.message}</p>
                                        <Label className="form-label" for="validationCustom02">
                                            {POSMachine} <span className="requireStar">*</span>
                                        </Label>
                                        <Controller
                                            name="posMachine"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={Payment}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        <p className="text-danger">{errors.posMachine?.message}</p>
                                        <Label className="form-label" for="validationCustom02">
                                            {AgentCollect} <span className="requireStar">*</span>
                                        </Label>
                                        <Controller
                                            name="agentCollect"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={Payment}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        <p className="text-danger">{errors.agentCollect?.message}</p>
                                        <Btn attrBtn={{ color: "primary" }} type="submit">{Submit}</Btn>
                                    </Row>
                                </Form>
                            </span>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl="9" md="12">
                    <Card className='cardWidth'>
                        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <H3>{OfflinePaymentMethod}</H3>
                            <div>
                                <Link to={`${process.env.PUBLIC_URL}/settings/payment-add`} className="btn btn-primary">{Add}</Link>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <span className='custom-span'>
                                <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                                    <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                        <Col xs="6" sm="4" md="2" lg="2">
                                            <input className="form-control" placeholder={Search} id="validationCustom01" type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                            />
                                        </Col>
                                        <Col xs="6" sm="6" md="4" lg="3">
                                            <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </span>
                            <div className="order-history table-responsive">
                                <DataTable
                                    data={paymentList}
                                    columns={[
                                        {
                                            name: <H4>Sl</H4>,
                                            selector: (row, index) => index + 1, // Assign SI dynamically based on current index
                                            width: '60px',
                                            sortable: false, // Disable sorting for SI
                                        },
                                        ...PaymentColumns // All other columns remain the same
                                    ]}
                                    pagination
                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default PaymentMethodContainer;