import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import Typeahead from '../../../CommonElements/TypeAhead';
import { DateText, Package, Recurring, Save, StartDate } from '../../../Constant';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { addBulkCustomerSubscription, addBulkCustomerSubscriptionRole, addCustomerSubscription, addCustomerSubscriptionRole, getPackage, getPackageRole } from '../../../CommonUrl';
import { Btn } from '../../../AbstractElements';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import moment from 'moment';
import { toast } from 'react-toastify';
import HandleApiError from '../../Common/ErrorImport/ErrorImport';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import DatePicker from 'react-datepicker';


const ApplyPackModal = ({ packageValue, onClose, clearselectedRows }) => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const [totalunitcost, settotalunitcost] = useState(0);
    const [totalDiscount, settotalDiscount] = useState(0);
    const [totalTaxamount, settotalTaxamount] = useState(0);
    const [grandTotl, setgrandTotl] = useState(0);
    const [packages, setPackage] = useState([]);
    const [installDate, setInstallDate] = useState(new Date());
    const [packageOption, setPackageOption] = useState([]);
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const [form, setForm] = useState([]);
    const type = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
    ];

    useEffect(() => {
        packageData();
    }, []);

    useEffect(() => {
        setPackageOption(
            packages.map((data) => ({
                value: data.id,
                label: <div className="d-flex justify-content-between"><div>{data.name}</div> <div>{authState.currency} {data.price}</div></div>,
                price: data.price,
                name: data.name,
                tax: data.tax_rates.map((t) => (t.name + '-' + t.tax_percentage + '%')),
                type: 'master',
                percentage: data.tax_rates.map((items) => items.tax_percentage),
            }))
        );
    }, [packages]);

    useEffect(() => {
        const newGrandTotal = form.reduce((acc, curr) => acc + curr.total, 0);
        const newUnitCost = form.reduce((acc, curr) => acc + curr.unitcost, 0);
        const newDiscount = form.reduce((acc, curr) => acc + curr.discount, 0);
        const newtaxAmount = form.reduce((acc, curr) => acc + curr.tax_amount, 0);
        settotalunitcost(parseFloat(newUnitCost).toFixed(authState.decimal));
        settotalDiscount(isNaN(newDiscount) ? 0 : parseFloat(newDiscount).toFixed(authState.decimal));
        settotalTaxamount(isNaN(newtaxAmount) ? 0 : parseFloat(newtaxAmount).toFixed(authState.decimal));

        setgrandTotl(isNaN(newGrandTotal) ? 0 : parseFloat(newGrandTotal).toFixed(authState.decimal));
    }, [form]);
    
    const handleChangeDate = date => {
        setInstallDate(date);
      };

    const packageData = async () => {
        setspinnerTrans(true)
        if (Array.isArray(packageValue)) {

            const findConnectiontype = packageValue.map(value => value.ConnectType);
            const checkConnectionAray = findConnectiontype.every(value => value === findConnectiontype[0]);

            if (checkConnectionAray) {
                let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPackage + `?connection_type=${packageValue[0].ConnectType}` : getPackageRole + `?connection_type=${packageValue[0].ConnectType}`);
                if (data.status === true) {
                    setPackage(data.data);
                } else {
                    HandleApiError(navigate, data.status);
                }
            } else {
                toast.error("Please select connection type from filter")
                clearselectedRows(true);
                setspinnerTrans(false)
            }
        } else {
            const connectionType = packageValue.ConnectType;
            const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPackage + `?connection_type=${connectionType}` : getPackageRole + `?connection_type=${connectionType}`);
            if (data.status === true) {
                setPackage(data.data);
            } else {
                HandleApiError(navigate, data.status);
            }
        }
        setspinnerTrans(false)
    };

    const handleProductSelect = (product) => {
        const existingIndex = form.findIndex(item => item.id === product.id);
        const map_percent = product.percentage.reduce((acc, curr) => acc + curr, 0);
        const discountamount = product.discount ?? 0;
        const product_disc = product.price - discountamount;
        const priceWithtax = (map_percent / 100) * product_disc;
        const unitCost = parseFloat(product.price).toFixed(authState.decimal);

        if (product.type === 'addon') {
            setForm([...form, { ...product, type: product.type, discount_type: 'cash', package_id: product.value, unitcost: product.price, name: product.name, quantity: 1, price: unitCost, discount: 0, tax: product.tax, tax_amount: priceWithtax, total: product.price + priceWithtax }]);
        } else if (existingIndex !== -1) {
            setForm([{ ...product, type: product.type, package_id: product.value, unitcost: product.price, name: product.name, quantity: 1, price: unitCost, discount: 0, discount_type: 'cash', tax: product.tax, tax_amount: priceWithtax, total: product.price + priceWithtax }]);
        } else {
            setForm([...form, { ...product, type: product.type, package_id: product.value, unitcost: product.price, name: product.name, quantity: 1, price: unitCost, discount: 0, discount_type: 'cash', tax: product.tax, tax_amount: priceWithtax, total: product.price + priceWithtax }]);
        }

    };

    const handletotalamount = (index, value) => {
        const parsedValue = parseFloat(value) || 0;
        setForm((prevForm) => {

            const updatedform = [...prevForm];
            const currentRow = { ...updatedform[index] };
            const map_percent = currentRow.percentage.reduce((acc, curr) => acc + curr, 0);
            currentRow.discount = isNaN(parsedValue) ? 0 : parsedValue;

            const productprice = currentRow.price;
            const productpricewithdiscount = productprice - currentRow.discount;
            const priceWithtax = (map_percent / 100) * productpricewithdiscount;
            currentRow.tax_amount = priceWithtax;

            const setTotal = (productpricewithdiscount + priceWithtax)
            currentRow.total = isNaN(setTotal) ? productpricewithdiscount : setTotal;
            updatedform[index] = currentRow;
            return updatedform;
        })

    };

    const handleRemove = (index, value) => {
        if (form[index].type === 'master') {
            setValue("package", '');
            setForm([]);
            setdisableOptions([]);
        } else {
            const updatedForm = [...form];
            const addonId = value.value;
            updatedForm.splice(index, 1);
            setForm(updatedForm);
            const updateAddonoption = disableOptions.filter(num => num !== addonId);
            setdisableOptions([...updateAddonoption, updateAddonoption]);
        }
    };

    const submit = async (data) => {
        SweetAlert.fire({
            title: `Are you sure? `,
            text: 'Note : Applying package is irreversible once processed.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (!result.value) {
                return;
            }
            setspinnerTrans(true)
            const today_Date = moment().format("DD-MM-YYYY");
            if (Array.isArray(packageValue)) {
                const customers_stb_id = packageValue.map(value => value.id);
                const postRequest = {
                    stb_id: customers_stb_id,
                    recurring: data.recurring.value,
                    category_id: packageValue[0].ConnectType,
                    start_date: today_Date,
                    invoice_date: today_Date,
                    package_id: data.package?.value,
                    items: form,
                    start_date: moment(installDate).format("DD-MM-YYYY"),
                }
                const addCustomerSubscriptionResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addBulkCustomerSubscription : addBulkCustomerSubscriptionRole, postRequest);
                if (addCustomerSubscriptionResponse.status === 200) {
                    if (addCustomerSubscriptionResponse.data.status === true) {
                        toast.success(addCustomerSubscriptionResponse.data.message);
                        onClose();
                        clearselectedRows(true);
                    } else {
                        toast.error(addCustomerSubscriptionResponse.data.message);
                        setspinnerTrans(false)
                    }

                } else {
                    HandleApiError(navigate, addCustomerSubscriptionResponse.status)
                }

                setspinnerTrans(false)
            } else {
                setspinnerTrans(true)
                const postRequest = {
                    customer_id: packageValue.customer_id,
                    stb_no: packageValue.StbNo,
                    recurring: data.recurring.value,
                    category_id: packageValue.ConnectType,
                    start_date: today_Date,
                    invoice_date: today_Date,
                    package_id: data.package.value,
                    items: form,
                    start_date: moment(installDate).format("DD-MM-YYYY"),
                }
                const addCustomerSubscriptionResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addCustomerSubscription : addCustomerSubscriptionRole, postRequest);
                if (addCustomerSubscriptionResponse.status === 200) {
                    if (addCustomerSubscriptionResponse.data.status === true) {
                        toast.success(addCustomerSubscriptionResponse.data.message);
                        onClose();
                        clearselectedRows(true);
                    } else {
                        toast.error(addCustomerSubscriptionResponse.data.message);
                        setspinnerTrans(false)
                    }
                } else {
                    HandleApiError(navigate, addCustomerSubscriptionResponse.status)
                }
            }
            setspinnerTrans(false)
        })
    };

    return (
        <>
            {spinnerTrans && <Transparentspinner />}
            <div className='col-md-12'>
                <div className="d-flex" >
                    <div className='recent-circle bg-primary'></div>
                    <div className="flex-grow-1">
                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(submit)}>
                            <Row>
                                <Col md="6">
                                    <Label className="form-label" for="insatall_date">
                                        {StartDate}
                                    </Label>
                                    <DatePicker
                                        id="insatall_date"
                                        style={{ padding: '12px 100px' }}
                                        className="form-control "
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        selected={installDate}
                                        onChange={handleChangeDate}
                                        required
                                    />
                                </Col>
                                <Col md="6">
                                    <Label className="form-label" >
                                        {Package} <span className="requireStar">*</span>
                                    </Label>
                                    <Controller
                                        name="package"
                                        control={control}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={packageOption}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleProductSelect(value)
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.customer?.message}</p>
                                </Col>
                                <Col md="6">
                                    <Label className="form-label">
                                        {Recurring}  <span className="requireStar">*</span>
                                    </Label>
                                    <Controller
                                        name="recurring"
                                        control={control}
                                        defaultValue={type[0]}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={type}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    <p className="text-danger">{errors.addDeduct?.message}</p>
                                </Col>
                            </Row>
                            <Row style={{ marginRight: '3px', marginLeft: '3px' }}>
                                {form.length > 0 && (
                                    <Table className="table border subcription-table mt-4">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th className="text-end">Unit cost</th>
                                                <th>Discount </th>
                                                <th>Tax</th>
                                                <th className="text-end">Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {form.map((item, index) => (
                                                <tr className="" key={index}>
                                                    <td className="">
                                                        <input
                                                            name={`items[${index}].name`}
                                                            placeholder='Item'
                                                            value={item.name}
                                                            readOnly
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td className=" col-2">
                                                        <input
                                                            name={`items[${index}].price`}
                                                            placeholder='Unit cost'
                                                            value={`${authState.currency} ${item.price}`}
                                                            className="form-control text-end"
                                                            readOnly
                                                        />
                                                    </td>

                                                    <td className=" col-2">
                                                        <input
                                                            name={`items[${index}].discount`}
                                                            placeholder='Discount'
                                                            defaultValue={0}
                                                            min={0}
                                                            onChange={(e) => handletotalamount(index, e.target.value)}
                                                            className="form-control text-center"
                                                        />
                                                    </td>
                                                    <td className=" col-2">
                                                        <input
                                                            name={`items[${index}].tax`}
                                                            placeholder='Tax'
                                                            value={item.tax}
                                                            className="form-control "
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td className=" col-2">
                                                        <input
                                                            name={`items[${index}].total`}
                                                            min={0}
                                                            placeholder='Total'
                                                            value={`${authState.currency} ${parseFloat(item.total).toFixed(authState.decimal)}`}
                                                            className="form-control text-end"
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <button type="button" onClick={() => handleRemove(index, item)} className="btn btn-danger"><i className="fa fa-trash-o"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-row-color">
                                                <th>Total</th>
                                                <th className="text-end"> Unit cost : <span className="">{authState.currency} {totalunitcost}</span></th>
                                                <th className="text-end"> Discount : <span>{authState.currency} {totalDiscount}</span></th>
                                                <th className="text-end "> Tax : <span>{authState.currency} {totalTaxamount}</span></th>
                                                <th className="text-end fs-5">Total : <span className="fw-bolder">{authState.currency} {grandTotl}</span></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                )}
                            </Row>
                            <FormGroup>
                            </FormGroup>
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApplyPackModal